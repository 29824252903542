import {
  SET_BE_ERROR,
  SET_LOADING,
  SetBackendErrorAction,
  SetLoadingAction,
  SetShiftStateAction,
  SHIFT_STATE
} from './types';

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setBackendError = (value: boolean): SetBackendErrorAction => ({
  type: SET_BE_ERROR,
  payload: value
});

export const setShiftState = (value: boolean): SetShiftStateAction => ({
  type: SHIFT_STATE,
  payload: value
});
