import './scss/ViewEditInvoice.scss';

import { Button, Col, notification, Pagination, Row, Table, TableColumnProps } from 'antd';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal.tsx';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { money } from 'helpers/list-helpers';
import { useI18n } from 'hooks/usei18n.ts';
import { useLocale } from 'hooks/useLocale';
import { measurementsLocale } from 'i18n/measurements.ts';
import { packagesLocale } from 'i18n/packages.ts';
import { IAppState } from 'interfaces/app-state';
import { PrintPricesModal } from 'layout/modals/printPrices/PrintPricesModal';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getInvoiceById, getInvoiceStockItemsById } from 'redux/inventory/invoice/actions';
import {
  IInvoiceStockItem,
  ShowInventoryItemResponse,
} from 'services/clinic/inventory/invoices/inventoryItems/models/showInventoryItemResponse';
import { indexClinicInvoiceStockItems } from 'services/clinic/inventory/invoices/invoicesService';
import { removeClinicStockItem } from 'services/clinic/inventory/stockService.ts';

interface Props {
  editingDisabled: boolean;
  setShowAddItemsToStockModal: any;
}

export const InvoiceItemsTable = ({
  editingDisabled,
  setShowAddItemsToStockModal
}: Props): JSX.Element => {
  const { clinicId, invoiceId }: any = useParams();
  const dispatch = useDispatch();
  const locale = useLocale('private.inventory.invoices');
  const currencies = useLocale('private.currencies').labels;
  const packages = useI18n(packagesLocale);
  const measurements = useI18n(measurementsLocale);

  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = React.useState<IInvoiceStockItem>(null);

  const [showPrintPricesModal, setShowPrintPricesModal] = useState(false);
  const [showPrintMultiplePricesModal, setShowPrintMultiplePricesModal] = useState(false);
  const [selectedStock, setSelectedStock] = useState(null);
  const [allInvoiceItems, setAllInvoiceItems] = useState([]);

  const { data, metadata } =
    useSelector<IAppState, ShowInventoryItemResponse>(
      ({ invoices }) => invoices.selectedInvoiceStockItems
    ) ?? {};

  const itemFormat = (item: IInvoiceStockItem): string => {
    const packagingFormat = packages[item?.purchase_format] || '';
    const unitOfMeasurement = measurements[item?.purchase_format] || '';
    return packagingFormat || unitOfMeasurement;
  };

  const getPrice = (item: IInvoiceStockItem, price: number, unitSellingPrice?: number) => {
    return (
      <Col>
        {item?.stock?.packaging_format !== 'none' && (
          <Row className='time-cell-row' style={{ width: 'max-content' }}>
            {`${packages[item?.stock?.packaging_format]}: ${price} ${currencies.uah}`}
          </Row>
        )}
        <Row className='time-cell-row' style={{ width: 'max-content' }}>
          {item?.stock?.unit_of_measurement !== 'pcs' ||
          item?.stock?.packaging_format !== 'none'
            ? measurements[item?.stock?.unit_of_measurement] + ': '
            : ''}
          {unitSellingPrice ?? (price / item?.stock?.quantity_per_package).toFixed(2)} {currencies.uah}
        </Row>
      </Col>
    );
  };

  const columns = useMemo(
    (): TableColumnProps<IInvoiceStockItem>[] => [
      {
        key: 'name',
        title: locale.labels.name,
        render: (e) =>
          ListFieldWithTooltip(
            <Link
              className={'custom-links'}
              to={`/clinic/${clinicId}/inventory/stock/${e.stock.id}`}
            >
              {e.stock.name}
            </Link>
          )
      },
      {
        key: 'series',
        title: locale.labels.series,
        render: (e) => e.series ?? '-'
      },
      {
        key: 'format',
        title: locale.labels.purchaseFormat,
        render: (e) => itemFormat(e) ?? '-'
      },
      {
        key: 'price_per_unit',
        title: locale.labels.pricePerItem,
        render: (e) => e.price_per_unit ? `${money(e.price_per_unit)} ${currencies.uah}` : '-'
      },
      {
        key: 'initial_quantity',
        title: locale.labels.purchaseQuantity,
        render: (e) => e.initial_quantity ?? '-'
      },
      {
        key: 'total_price',
        title: locale.labels.totalPrice,
        render: (e) => `${e.total_price ? `${money(e.total_price)} ${currencies.uah}` : '-'}`
      },
      {
        key: 'selling_price',
        title: locale.labels.sellingPrice,
        render: (e) => getPrice(e, e?.selling_price, e?.unit_selling_price)
      },
      {
        key: 'action',
        title: locale.labels.actions,
        render: (item: IInvoiceStockItem) => (
          <Row>
            {!item.deleted && (
              <Button
                type='primary'
                onClick={() => {
                  setShowPrintPricesModal(true);
                  setSelectedStock(item);
                }}
                shape={'circle'}
              >
                <span className='icofont icofont-print' />
              </Button>
            )}
            {!item.deleted && !editingDisabled && (
              <Button
                style={{
                  marginLeft: '5px'
                }}
                onClick={() => {
                  setShowDeleteModal(true);
                  setItemToDelete(item);
                }}
                danger
                type='primary'
                shape={'circle'}
              >
                <span className='icofont icofont-trash' />
              </Button>
            )}
          </Row>
        )
      }
    ],
    [data]
  );

  const onDeleteConfirmClick = (): void => {
    removeClinicStockItem(clinicId, itemToDelete.stock.id, itemToDelete.id)
      .then(() => {
        notification.success({ message: locale.messages.recordSuccessfullyDeleted });
        dispatch(getInvoiceById(clinicId, invoiceId));
        dispatch(getInvoiceStockItemsById(clinicId, invoiceId, metadata?.page));
        setShowDeleteModal(false);
      })
      .catch(() => {
        console.log('Error deleting stock item');
      });
  };

  const onPageChange = (page: number): void => {
    dispatch(getInvoiceStockItemsById(clinicId, invoiceId, page));
  };

  const printPricesClick = () => {
    setShowPrintMultiplePricesModal(true);
    indexClinicInvoiceStockItems(clinicId, invoiceId, 0).then(res => {
      const filteredInvoiceItemsData = res.data.filter(el => !el.deleted);
      setAllInvoiceItems(filteredInvoiceItemsData.map(el => el.stock));
    });
  };

  return (
    <>
      <Table
        pagination={false}
        className='accent-header stock-list'
        rowKey='id'
        dataSource={data}
        columns={columns}
        rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
      />
      <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'flex-end' }}>
        <Pagination
          defaultCurrent={1}
          pageSize={20}
          current={metadata?.page}
          total={metadata?.total}
          showSizeChanger={false}
          showLessItems
          onChange={onPageChange}
        />
        <Row>
          <Button
            type='primary'
            onClick={printPricesClick}
            style={{borderRadius: 30 }}
          >
            <span className='icofont icofont-print' />
            {locale.buttons.printPrices}
          </Button>
          {!editingDisabled && (
            <div
              className='invoice-row-container__add-item-container'
              style={{ width: 'fit-content' }}
            >
              <Button
                className='icofont icofont-plus'
                type={'primary'}
                style={{ borderRadius: 30, float: 'right', marginLeft: 8 }}
                onClick={() => setShowAddItemsToStockModal(true)}
              >
                {locale.buttons.addInvoiceStockItems}
              </Button>
            </div>
          )}
        </Row>
        {showDeleteModal && (
          <ConfirmDeleteModal
            open={showDeleteModal}
            onCancel={() => setShowDeleteModal(false)}
            onOk={onDeleteConfirmClick}
          />
        )}
        {showPrintPricesModal && (
          <PrintPricesModal
            setShowPrintPricesModal={setShowPrintPricesModal}
            prices={selectedStock?.stock?.prices}
            stock={selectedStock?.stock}
          />
        )}
      </Row>
        {showPrintMultiplePricesModal && <PrintPricesModal setShowPrintPricesModal={setShowPrintMultiplePricesModal}
        stock={allInvoiceItems} multipleStocksMode showChooseNewStockBtn />}
    </>
  );
};
