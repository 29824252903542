import './ClientDetails.scss';

import { Avatar as AvatarView, Button, Col, Form, Row, Spin, Tooltip, Typography } from 'antd';
import syringeIcon from 'assets/img/syringe.svg';
import tomb from 'assets/img/tomb.svg';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { TextEditorView } from 'components/TextEditorView';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { animalIdUrl } from 'constants/common';
import {
  animalSex,
  yesNoBooleanOptions,
  yesNoBooleanOptionsWithUnknown,
} from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import { weight } from 'helpers/list-helpers';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import { IPatient } from 'interfaces/patient';
import PatientForm from 'layout/modals/editPatient/ViewAndEditPatient';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  getPatientAllVaccinationsData,
  setSelectedPatienAllVaccinationsData,
  showUpdateModal,
} from 'redux/patients/actions';
import { AppState } from 'redux/store';
import { localizedPetData } from 'utils/localized-pet-data';

import { getAnimalAge, getDefaultAvatar } from '../patients/helpers/PatientHelper';
import { aggressiveness } from './helpers/TagSelector';

const getLocalisedLabel = (
  locale: Record<string, any>[],
  value: string | boolean,
  localeCode: string
): string => locale.find((el) => el.value === value)?.[localeCode] ?? '-';

type Props = {
  clinicId: number;
  data: IPatient;
  accessGroup: boolean;
  hidePatientTopSection?: boolean;
  callbackAfterPatientEdit?: () => void;
};

const ClientDetailsPetsTab = ({
  clinicId,
  data,
  accessGroup,
  hidePatientTopSection,
  callbackAfterPatientEdit
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locale = useLocale('private.clients.client');
  const patientLocale = useLocale('private.patients.patient-page');

  const EMPTY_CONTENT = '<p><br></p>';

  const localeCode = useSelector(({ localeCode }: IAppState) => localeCode);
  const showEditPatientModal = useSelector(({ patients }: AppState) => patients.showUpdateModal);
  const vaccinationData = useSelector(
    ({ patients }: IAppState) => patients.selectedPatientAllVaccinationsData
  );

  const [editPatientForm] = Form.useForm();
  const localizedPetInfo = useMemo(
    (): Record<string, string> => localizedPetData(data, localeCode),
    [data]
  );

  useEffect(() => {
    dispatch(setSelectedPatienAllVaccinationsData(null));
    dispatch(getPatientAllVaccinationsData(clinicId, data.id, 0));
  }, [data.id]);

  const petSex = useMemo(() => getLocalisedLabel(animalSex, data.sex, localeCode), [data]);
  const petNeutering = useMemo(
    () => getLocalisedLabel(yesNoBooleanOptionsWithUnknown, data.neutering, localeCode),
    [data]
  );

  const petLivesOutdoor = useMemo(
    () => getLocalisedLabel(yesNoBooleanOptions, data.lives_outdoor, localeCode),
    [data]
  );
  const petDeciased = useMemo(
    () => getLocalisedLabel(yesNoBooleanOptions, data.deceased, localeCode),
    [data]
  );

  const PatientDetailsTopSections = useMemo((): JSX.Element => {
    const name = data.name ?? '';
    const birth_date = data.birth_date ? getAnimalAge(locale, data.birth_date) : '';
    const species = data.species ?? '';

    const getDot = (str1: string, str2: string): ', ' | '' => (str1 && str2 ? ', ' : '');

    return (
      <>
        <Row>
          <Typography
            id='patient-name-age'
            style={{ fontSize: 18, fontWeight: 'bold', marginRight: 4 }}
          >
            {name}
            {getDot(name, birth_date)}
          </Typography>
          {data.date_of_death ? (
            <Typography id='patient-death-icon'>
              <Tooltip
                trigger={'hover'}
                placement='topLeft'
                title={getAnimalAge(patientLocale, data.birth_date, data.date_of_death)}
              >
                <img src={tomb} style={{ width: 22, height: 22 }} />
              </Tooltip>
            </Typography>
          ) : (
            <Typography id='patient-birth-date' style={{ fontSize: 18 }}>
              {birth_date}
            </Typography>
          )}
          {!!data.aggressiveness && aggressiveness(data.aggressiveness, localeCode)}
        </Row>
        <Row>
          <Typography
            id='patient-species'
            style={{ fontSize: 16 }}
            className='client-details__patient-details__top-section__right-side__species'
          >
            {' '}
            {localizedPetInfo?.species ?? '-'}
            {getDot(species, localizedPetInfo?.breed)}
          </Typography>
          {!!localizedPetInfo?.breed && (
            <Typography id='patient-breed' style={{ fontSize: 16 }}>
              {localizedPetInfo.breed}
            </Typography>
          )}
        </Row>
      </>
    );
  }, [data]);

  const VaccinationsData = useMemo((): JSX.Element => {
    const res = vaccinationData?.data?.filter((el) => dayjs().isBefore(dayjs(el.expiration_date)));
    if (!res?.length) {
      return (
        <>
          <span style={{ fontWeight: 600 }}>{locale.labels.lastVaccination}:</span>{' '}
          {locale.labels.vaccinationNoData}
        </>
      );
    }

    if (res.length === 1) {
      return (
        <>
          <span style={{ fontWeight: 600 }}>{locale.labels.lastVaccination}:</span> {res[0].name} (
          {res[0].manufacturer}) {locale.labels.due}{' '}
          {dayjs(res[0].expiration_date).format('DD.MM.YYYY')}
        </>
      );
    }

    let vaccsToShow = [];
    let vaccsInTooltip = [];

    if (res?.length > 4) {
      vaccsToShow = res
        .slice(0, 4)
        .map((el: { name: string; manufacturer: string; expiration_date: string }) => (
          <div key={el.name}>
            {ListFieldWithTooltip(`${el.name} (${el.manufacturer})`, 45)} {locale.labels.due}{' '}
            {dayjs(el.expiration_date).format('DD.MM.YYYY')}
          </div>
        ));
      vaccsInTooltip = res.slice(4, res.length).map((el) => (
        <div key={el.name}>
          {el.name} ({el.manufacturer}) {locale.labels.due}{' '}
          {dayjs(el.expiration_date).format('DD.MM.YYYY')}
        </div>
      ));
    } else {
      vaccsToShow = res.map((el) => (
        <div key={el.name}>
          {ListFieldWithTooltip(`${el.name} (${el.manufacturer})`, 45)} {locale.labels.due}{' '}
          {dayjs(el.expiration_date).format('DD.MM.YYYY')}
        </div>
      ));
    }

    const moreVaccsToShow =
      res?.length > 4 ? (
        <Tooltip overlayStyle={{ maxWidth: '500px' }} title={vaccsInTooltip}>
          <span style={{ color: 'grey', cursor: 'pointer' }}>...+{res.length - 4}</span>
        </Tooltip>
      ) : (
        ''
      );

    return (
      <>
        <span style={{ fontWeight: 600 }}>{locale.labels.lastVaccination}:</span> {vaccsToShow}
        {moreVaccsToShow}
      </>
    );
  }, [vaccinationData?.data]);

  return (
    <>
      {!hidePatientTopSection && (
        <Row className='client-details__patient-details__top-section'>
          <Col style={{ width: '100%' }}>
            <Row>
              <AvatarView
                size={100}
                style={{ marginRight: 15 }}
                src={data.avatar ? data.avatar : getDefaultAvatar(data.species)}
              />
              <Row className='client-details__patient-details__top-section__right-side'>
                <Col>
                  {PatientDetailsTopSections}
                  {data.chip_number && (
                    <Typography id='patient-chip' style={{ fontSize: 16 }}>
                      <span>{locale.labels.chip}: </span>
                      <Link className={'custom-links'} to={animalIdUrl + data.chip_number}>
                        {data.chip_number}
                      </Link>
                    </Typography>
                  )}
                </Col>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Button
                    id='patient-page-redirect'
                    type='primary'
                    onClick={() => {
                      navigate(`/clinic/${clinicId}/patients/${data.id}`);
                    }}
                    style={{ borderRadius: 30, whiteSpace: 'normal' }}
                  >
                    {locale.buttons.viewPatient}
                    <span className='icofont icofont-external-link icon-after-btn' />
                  </Button>
                </div>
              </Row>
            </Row>
          </Col>
        </Row>
      )}
      {data.warnings && data.warnings !== EMPTY_CONTENT && (
        <Row align='middle' className='client-details__patient-details__alergies-container'>
          <Col className='icon-style'>
            <span className='icofont-warning info-icon' />
          </Col>
          <Col className='text'>
            <TextEditorView value={data.warnings} id='patient-warnings' borderless />
          </Col>
        </Row>
      )}
      <Row align='middle' className='client-details__patient-details__vacination-container'>
        <Col className='icon-style'>
          <img src={syringeIcon} style={{ width: 27, marginTop: 10, marginRight: 10 }} />
        </Col>
        {vaccinationData ? (
          <Col id='patient-vaccination' className='text'>
            {VaccinationsData}
          </Col>
        ) : (
          <Spin spinning></Spin>
        )}
      </Row>

      <div className='client-details__patient-details__info-container grid-container'>
        <div className='left-column'>
          <Col>
            <Row>
              {' '}
              <span className='labels'>{patientLocale.labels.dateOfBirth}: </span>
              <span id='patient-birth-date'>
                {data.birth_date ? moment(data.birth_date).format('DD.MM.YYYY') : '-'}
              </span>
            </Row>
            <Row>
              {' '}
              <span className='labels'>{patientLocale.labels.sex}: </span>
              <span id='patient-sex'>{petSex}</span>
            </Row>
            <Row>
              {' '}
              <span className='labels'>{patientLocale.labels.weight}: </span>
              <span id='patient-weight'>{data.weight ? weight(data.weight) : '-'}</span>
            </Row>
            <Row>
              {' '}
              <span className='labels'>{patientLocale.labels.neutering}: </span>
              <span id='patient-neutering'>{petNeutering}</span>
            </Row>
            <Row>
              {' '}
              <span className='labels'>{patientLocale.labels.bloodType}: </span>
              <span id='patient-blood-type'>{data.blood_type ?? '-'}</span>
            </Row>
          </Col>
        </div>

        <div className='right-column'>
          <Col>
            <Row>
              {' '}
              <span className='labels'>{patientLocale.labels.tattoo}: </span>
              <span id='patient-tattoo'>{data.tattoo ?? '-'}</span>
            </Row>
            <Row>
              {' '}
              <span className='labels'>{patientLocale.labels.livesOutdoor}: </span>
              <span id='patient-lives-outdoor'>{petLivesOutdoor}</span>
            </Row>
            <Row>
              {' '}
              <span className='labels'>{patientLocale.labels.color}: </span>
              <span id='patient-color'>{localizedPetInfo?.color || '-'}</span>
            </Row>
            <Row>
              {' '}
              <span className='labels'>{patientLocale.labels.coat}: </span>
              <span id='patient-coat'>{localizedPetInfo?.coat || '-'}</span>
            </Row>
          </Col>
        </div>
      </div>
      <Row style={{ marginBottom: 12 }}>
        {data.deceased && (
          <Col span={24}>
            <div className='client-details__patient-details__info-container__divider' />
            <span style={{ fontWeight: 600 }}>{patientLocale.labels.deceased}: </span>
            <span id='patient-deceased'>
              {petDeciased + '\u00A0'}({dayjs(data.death_date).format('DD.MM.YYYY')})
            </span>
          </Col>
        )}
      </Row>

      <Row style={{ marginLeft: 10 }}>
        <div className='labels'>{patientLocale.labels.lifeAnamnesis}: </div>
        <TextEditorView value={data.life_anamnesis} id='patient-life-anamnesis' />
      </Row>
      <Row style={{ marginLeft: 10, marginBottom: 16 }}>
        <span className='labels'>{patientLocale.labels.notes}: </span>
        <TextEditorView value={data.notes} id='patient-notes' />
      </Row>

      {!!showEditPatientModal && (
        <VLXModal
          title={locale.labels.editPatient}
          centered={true}
          form={editPatientForm}
          open={showEditPatientModal}
          onModalClose={() => dispatch(showUpdateModal(false))}
          width={800}
        >
          <PatientForm
            formEditMode
            clinicId={clinicId}
            patientId={data.id}
            form={editPatientForm}
            callbackFunc={callbackAfterPatientEdit}
            closeModal={() => dispatch(showUpdateModal(false))}
            fixedBtns
            initialValues={{
              ...data,
              birth_date: data.birth_date && dayjs(data.birth_date),
              owner: data.primary_contact_id,
              death_date: data.death_date && dayjs(data.death_date)
            }}
          />
        </VLXModal>
      )}
      <div
        style={{
          visibility: !showEditPatientModal ? 'visible' : 'hidden',
          padding: '0 0 10px'
        }}
      >
        <ViewDetailsButtonsGroup
          hideCancelBtn
          fixed
          accessGroup={accessGroup}
          editMode={false}
          onEditClick={() => dispatch(showUpdateModal(true))}
          hideDeleteBtn
        />
      </div>
    </>
  );
};

export default ClientDetailsPetsTab;
