import './PatientsSelect.scss';

import { Col, Row, Select, Spin, Typography } from 'antd';
import * as config from 'config/config-manager.ts';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import { ISearchCriteria } from 'interfaces/search-criteria.ts';
import { ISortCriteria } from 'interfaces/sort-criteria.ts';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from '../../../components/Avatar.tsx';
import { getDefaultAvatar } from '../../../pages/patients/helpers/PatientHelper.tsx';
import { getPatients } from '../../../redux/patients/actions.ts';

const { Option } = Select;
const { Text } = Typography;

type Props = {
  onChange?: (value?) => void;
  clinicId: number;
  selectedPatientId?: number;
  disabled?: boolean;
  setSelectedOwnerId?: (id: number) => void;
  setSelectedPatientsIds?: (ids: number[]) => void;
};

export const PatientsSelect = (
  {
    onChange,
    clinicId,
    disabled,
    selectedPatientId,
    setSelectedOwnerId,
    setSelectedPatientsIds
  }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const patients = useSelector(({ patients }: IAppState) => patients.data);
  const locale = useLocale('private.select-with-required-search');

  const [loading, setLoading] = useState<boolean>(false);

  useEffect((): void => {
    if (selectedPatientId) {
      performSearch(searchByIdObject(selectedPatientId));
    }
  }, [selectedPatientId]);

  const sortObject: ISortCriteria = {
    sort_key: 'name',
    sort_order: 'asc'
  };

  const searchObject = (values: string[]): ISearchCriteria[] => {
    return values.map((value) => ({
      name: 'composite',
      value,
      isArrayValue: true
    }));
  };

  const searchByIdObject = (id: number): ISearchCriteria[] => [
    {
      name: 'id',
      value: id.toString()
    }
  ];

  const performSearch = (searchCriteria: ISearchCriteria[]): void => {
    dispatch(getPatients(clinicId, 0, searchCriteria, sortObject, () => setLoading(false)));
  };

  const onPatientSearch = (value: string): void => {
    if (value.length < config.selectSearchMinSymbols) return;

    const searchValues: string[] = value.split(' ').filter((item: string) => item !== '');

    setLoading(true);
    performSearch(searchObject(searchValues));
  };

  const onPatientSelect = (value: number): void => {
    const patient = patients.find((item) => item.id === value);
    setSelectedOwnerId(patient?.owner?.id);
    setSelectedPatientsIds([value]);
  };

  const patientsList: JSX.Element[] = useMemo(
    () =>
      patients?.map((item) => {
        return (
          <Option
            key={`${item.id}_${crypto.randomUUID()}`}
            data={item}
            value={item.id}
            title={`${item.name} - ${item.owner?.name} (+${item.owner?.phone_number})`}
          >
            <Row style={{ width: '100%', flexWrap: 'nowrap' }}>
              <Col style={{ marginRight: '5px', minWidth: 50 }}>
                <div className={'pet-avatar-select'}>
                  <Avatar
                    src={item.avatar ? item.avatar : getDefaultAvatar(item.species)}
                  />
                </div>
              </Col>
              <Col className='pet-info-box'>
                <Col><Text className='pet-info-box__text'>{item.name}</Text></Col>
                <Row align='middle'>
                  <Col xs={24} sm={24} md={12} lg={12} style={{ marginRight: '5px' }}>
                    <Text type='secondary'>{item.owner.name}</Text>
                  </Col>
                  {item.owner.phone_number && <Col xs={24} sm={24} md={12} lg={12}>
                    <Text type='secondary'>{`+${item.owner.phone_number}`}</Text>
                  </Col>}
                </Row>
              </Col>
            </Row>
          </Option>
        );
      }),
    [patients]
  );

  return (
    <Select
      placeholder={locale.placeholders.inputMinForSearch}
      id={'patients-select-with-search'}
      virtual={false}
      onChange={onChange}
      notFoundContent={loading ? <Spin size='small' /> : null}
      value={patients?.length ? selectedPatientId : null}
      disabled={disabled}
      filterOption={false}
      showSearch
      allowClear
      onSearch={debounce(onPatientSearch, config.selectSearchDelay)}
      onSelect={onPatientSelect}
    >
      {patientsList}
    </Select>
  );
};
