import './AuditForm.scss';

import { Checkbox, Divider, Form, Input, List, Spin } from 'antd';
import VLXCompositeSearch from 'components/VLXCompositeSearch';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getStock } from 'redux/inventory/stock/actions';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem';

type SortedStockItem = IStockItem & { disabled: boolean };

const AuditItemsForm = ({
  children,
  onFormEditingFinish,
  selectedIds,
  setSelectedIds
}: any): JSX.Element => {
  const locale = useLocale('private.inventory.audit');
  const [form] = Form.useForm();
  const stockList = useSelector(({ stock }: IAppState) => stock.data);
  const stockLoading = useSelector(({ stock }: IAppState) => stock.loading);
  const addedItemsIds = useSelector(({ audit }: IAppState) => audit.headItems);

  const [sortedStockList, setSortedStockList] = useState<SortedStockItem[]>();

  useEffect((): void => {
    if (addedItemsIds) {
      const sortedList = stockList
        .filter((item) => !item.deleted)
        .map((item) => ({
          ...item,
          disabled: !!Object.values(addedItemsIds).find(({ stock_id }) => stock_id === item.id)
        }))
        .sort((a, b) => +a.disabled - +b.disabled);

      setSortedStockList(sortedList);
    }
  }, [addedItemsIds, stockList]);

  const onSelectItem = (checked: boolean, id: number): void => {
    if (checked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };

  const selectAll = (checked: boolean): void => {
    if (checked) {
      setSelectedIds(sortedStockList.filter((item) => !item.disabled).map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const onFinish = (): void => {
    onFormEditingFinish(stockList);
  };

  return (
    <Spin spinning={stockLoading}>
      <Form
        key={'auditItemsForm'}
        onFinish={onFinish}
        autoComplete='off'
        layout='vertical'
        form={form}
      >
        <h4 style={{ marginBottom: 10 }}>{locale.labels.stock}</h4>
        <Form.Item name='items' hidden>
          <Input />
        </Form.Item>
        <VLXCompositeSearch
          customPageNumber={0}
          action={getStock}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) e.preventDefault();
          }}
        />

        {!!stockList.length ? (
          <div style={{ marginBottom: '20px' }}>
            <h4>{locale.labels.items}:</h4>
            <Checkbox onChange={(e) => selectAll(e.target.checked)}>
              {locale.labels.selectUnselectAll}
            </Checkbox>
            <Divider style={{ margin: '15px 0' }} />
            <List
              pagination={{ position: 'bottom', align: 'center' }}
              dataSource={sortedStockList}
              renderItem={(item: SortedStockItem) => (
                <List.Item>
                  <div key={`item_${item.id}`}>
                    <Checkbox
                      checked={
                        item.disabled ||
                        selectedIds.find((selectedId: number) => selectedId === item.id)
                      }
                      disabled={item.disabled}
                      onChange={(e) => onSelectItem(e.target.checked, item.id)}
                    >
                      {item?.name} {item.disabled && locale.labels.alreadyAdded}{' '}
                    </Checkbox>
                  </div>
                </List.Item>
              )}
            />
          </div>
        ) : (
          <h6>{locale.labels.noResultsForFilter}</h6>
        )}
        {children}
      </Form>
    </Spin>
  );
};

export default AuditItemsForm;
