import './scss/PrintPricesForm.scss';

import { Form, FormInstance } from 'antd';
import useClinicId from 'hooks/useClinicId';
import { ILocale } from 'interfaces/locale';
import { StockSelectLocalState } from 'layout/components/stockSelect/StockSelectLocalState';
import { useGetResidual } from 'pages/inventory/stock/utils/useGetResidual';
import React from 'react';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem';

type Props = React.PropsWithChildren<{
  stock: any;
  locale: ILocale;
  name?: number;
  form: FormInstance;
  selectNewStock: (stock) => void;
}>;

export const PrintMultipleStocksFormItem = ({
  stock, locale, name, form, selectNewStock
}: Props): JSX.Element => {
  const clinicId = useClinicId();

  const { unitOfMeasurement, packagingFormat, noPackageFormat } =
    useGetResidual(stock);

  const onStockSelect = (stockData: IStockItem) => {
    if (stockData?.id) {
      stockData.prices = stockData.prices.map(item => ({ ...item, priceId: item.id }));
      selectNewStock(stockData);
      form.setFieldValue(['stock', name], stockData);
      form.setFieldValue(['stock', name, 'pricesToPrint'], stockData.prices.filter(el => el.type === 'default'));
    }
  };
    
  return (
    <>
      {stock ? <div className='stock-name-box__left-side'>
        <span style={{ fontWeight: 600, fontSize: 18, marginTop: 25, display: 'block' }}>{stock?.name}</span>
        <div style={{ marginBottom: 25 }}>{!noPackageFormat && (<span>{locale.labels.format}
          : {packagingFormat} (1 {packagingFormat} = {stock?.quantity_per_package} {unitOfMeasurement})</span>)}
        </div>
      </div>
      : <div className='stock-name-box__left-side'>
          <Form.Item
          className='add-service-form-stock__name long-value-form-item'
          label={locale.labels.name}
          name={['stock', name]}
        >
          <StockSelectLocalState
            trackOutOfStock={false}
            returnFullStockInfo
            onChange={onStockSelect}
            clinicId={clinicId}
            locale={locale} />
        </Form.Item>
      </div>
      }
    </>
  );
};
