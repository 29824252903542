import { Avatar, Col, Form, Row } from 'antd';
import LogoSvg from 'assets/img/logoBlack.svg';
import { TextEditorView } from 'components/TextEditorView';
import dayjs from 'dayjs';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { companyTypes } from 'i18n/companyTypesSelect';
import { IAppState } from 'interfaces/app-state';
import { IPatient } from 'interfaces/patient';
import { IPrintCongigurationParams } from 'layout/modals/printConfiguration/PrintConfigurationForm';
import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClinic } from 'redux/clinic/actions';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';

import { ViewEditInvoiceItemsTable } from '../../../inventory/invoices/ViewEditInvoiceItemsTable';
import BillsDetailsSalesSection from './BillsDetailsSalesSection';
import { InvoicePrintInfo } from './InvoicePrintInfo';
import { PatientPrintInfo } from './PatientPrintInfo';

const EMPTY_CONTENT = '<p><br></p>';

interface Props {
  data: IBillDetails;
  billId: number;
  currentPrintConfig?: IPrintCongigurationParams;
}

export const BillContentToPrint = ({ data, billId, currentPrintConfig }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const dispatch = useDispatch();
  const [editBillForm] = Form.useForm();
  const companyTypesOptions = useI18n(companyTypes);
  const locale = useLocale('private.finances.bills');
  const currencies = useLocale('private.currencies').labels;

  const clinic = useSelector(({ clinic }: IAppState) => clinic);

  const isAppointmentBill = data?.appointment;

  const headerContentAvaliable = currentPrintConfig?.header && currentPrintConfig?.header !== EMPTY_CONTENT;
  const footerContentAvaliable = currentPrintConfig?.footer && currentPrintConfig?.footer !== EMPTY_CONTENT;

  useEffect(() => {
    dispatch(getClinic(clinicId));
  }, [clinicId]);

  const renderBillDetailsByBillType = ({ sale, invoice, client }: IBillDetails): ReactNode => {
    if (isAppointmentBill) {
      return (
        <>
          {data.appointment.patients.map((item: IPatient) => (
            <PatientPrintInfo patient={item} key={item.id} printAnamnesis={currentPrintConfig?.print_anamnesis}/>
          ))}
          <Row style={{ marginTop: 30, marginBottom: 10 }} justify={'space-between'}>
            <Col></Col>
            <Col>{data.appointment?.doctor?.name} ___________________</Col>
          </Row>
          <InvoicePrintInfo data={data} billId={billId} showFreeItems={currentPrintConfig?.print_free_items}/>
        </>
      );
    }
    if (sale) {
      return (
        <BillsDetailsSalesSection
          renderTableOnly
          form={editBillForm}
          initialValues={data}
          clientDetails={client}
        />
      );
    }
    if (invoice) {
      return <ViewEditInvoiceItemsTable data={data.invoice.stock} />;
    }
  };

  const getDocumentName = () => {
    if (isAppointmentBill) {
      return locale.labels.specialistConsultationReport;
    } else if (!data?.sale && !data?.invoice) {
      return `${data?.name} №${billId}`;
    }
    return `${locale.labels.invoice} №${billId}`;
  };

  const renderClinicInfo = () => {
    if ((isAppointmentBill || data?.sale) && headerContentAvaliable) return;

    return <>
      <h4 style={{ textAlign: 'center', marginBottom: 15 }}>
        {companyTypesOptions[clinic?.company_type] || ''} "{clinic?.name}"
      </h4>
      <p style={{ textAlign: 'center', marginBottom: 5 }}>{clinic?.location}</p>
      {clinic?.phone_number && (
        <p style={{ textAlign: 'center' }}>
          +{clinic?.country_code}
          {clinic?.phone_number}
        </p>
      )}
    </>;
  };

  return (
    <>
      <div className='print-content-wrapper print-content-wrapper__header'>
       {currentPrintConfig?.logo &&  <Avatar src={currentPrintConfig?.logo} />}
        {headerContentAvaliable && <TextEditorView borderless value={currentPrintConfig?.header} showAll />}
      </div>
      {renderClinicInfo()}
      <h5 style={{ textAlign: 'center', fontSize: '1.2rem' }}>{getDocumentName()}</h5>

      <Col>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.date}: </span>
          <span id='date'>{data?.date ? dayjs(data?.date).format('DD.MM.YYYY HH:mm') : '-'}</span>
        </Col>
        <Col>
          {!!data?.client?.name && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.client}: </span>
              <span id='client'>
                {data?.client?.name} {data?.client?.phone_number ? `(+${data?.client?.phone_number})` : ''}
              </span>
            </>
          )}
        </Col>
        {!!data && renderBillDetailsByBillType(data)}
      </Col>

      <Col
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end'
        }}
      >
        <Col>
          {data?.amount !== null && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.subtotal}: </span>
              <span id='subtotal'>
                {money(data?.amount || 0)} {currencies.uah}
              </span>
            </>
          )}
        </Col>
        <Col>
          <>
            <span style={{ fontWeight: 600 }}>{locale.labels.discount}: </span>
            <span id='discount-amount'>
              {money(data?.services_discount?.amount + data?.stock_discount?.amount || 0)}{' '}
              {currencies.uah}
            </span>
          </>
        </Col>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.total}: </span>
          <span id='total-amount'>
            {money(data?.final_amount || 0)} {currencies.uah}
          </span>
        </Col>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.payed}: </span>
          <span id='total_paid'>
            {money(data?.final_amount - data?.remaining_amount || 0)} {currencies.uah}
          </span>
        </Col>
        <Col>
          <span style={{ fontWeight: 600 }}>{locale.labels.billDebt}: </span>
          <span id='remaining-amount'>
            {money(data?.remaining_amount || 0)} {currencies.uah}
          </span>
        </Col>
      </Col>
      {footerContentAvaliable && <div className='print-content-wrapper'>
        <TextEditorView showAll borderless value={currentPrintConfig?.footer} />
      </div>}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          top: 5,
          width: '100%'
        }}
      >
        <span style={{ paddingRight: 10 }}>{locale.labels.generatedBy}</span>
        <img src={LogoSvg} style={{ height: 20 }} />
      </div>
    </>
  );
};
