import './Bills.scss';

import { Col, Form, Row, Spin } from 'antd';
import { DeletedInfo } from 'components/EntityDetails/DeletedInfo';
import { StatusTag } from 'components/StatusTag';
import { TextEditorView } from 'components/TextEditorView';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { billType, paymentStatuses, yesNoBooleanOptions } from 'constants/dictionary/default/selectOptions';
import { localDateTime } from 'helpers/DateTimeUtils';
import { money } from 'helpers/list-helpers';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import EditBill from 'layout/modals/finances/addBill/EditBill';
import { ViewEditBill } from 'layout/modals/viewEditBill/ViewEditBill';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBillsDetails, getBillsPaymentsDetails, removeBill, showModal } from 'redux/finances/bills/actions';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';
import valueFromList from 'utils/value-from-list';

import { ViewEditInvoiceItemsTable } from '../../inventory/invoices/ViewEditInvoiceItemsTable';
import getType from '../helpers/TagSelector';
import BillsDetailsPayments from './BillsDetailsPayments';
import { BillContentToPrintWrapper } from './print/BillContentToPrintWrapper';
import BillsDetailsSalesSection from './print/BillsDetailsSalesSection';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal.tsx';
import { PageInitialLoader } from 'components/PageInitialLoader.tsx';

const BillsDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const { clinicId, billId } = useParams();
  const locale = useLocale('private.finances.bills');
  const currencies = useLocale('private.currencies').labels;
  const userAccess = useUserAccess();
  const localizedTypes = useLocalizedList(billType);

  const data = useSelector(({ bills }: IAppState) => bills.selectedBill);
  const showSimpleBillModal = useSelector(({ bills }: IAppState) => bills.showModal);
  const billsLoading = useSelector(({ bills }: IAppState) => bills.loading);
  const paymentsLoading = useSelector(({ payments }: IAppState) => payments.loading);
  const billPayments = useSelector(({ bills }: IAppState) => bills.billPayments);
  const openedShift = useSelector(({ common }: IAppState) => common.shiftOpened);

  const [dataLoading, setDataLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [editBillForm] = Form.useForm();
  const [viewBillForm] = Form.useForm();

  useEffect(() => {
    if (clinicId && billId) dispatch(getBillsDetails(+clinicId, +billId));
    if (clinicId && billId) dispatch(getBillsPaymentsDetails(+clinicId, +billId));
  }, [clinicId]);

  const getBillTypeTag = (type) => {
    const getLocalizedLabel = (val: any) => {
      return localizedTypes.find((el: any) => el.value === val);
    };
    return { value: type, label: getLocalizedLabel(type).label };
  };

  const localizedPaymentStatuses = useLocalizedList(paymentStatuses);
  const localizedYesNoBooleanOptions = useLocalizedList(yesNoBooleanOptions);

  const renderTopBillDetailsByBillType = () => {
    if (data?.sale) {
      return (
        <Row>
          <Col span={24} md={12} className='appointment-details-top-container__left-column'>
            {data?.deleted &&
              <DeletedInfo deleted_at={data?.deleted_at} deleted_by={data?.deleted_by} />}
            <Col>
              <span style={{ fontWeight: 600 }}>{locale.labels.date}: </span>
              <span id='date'>
                {localDateTime(data?.date)}
              </span>
            </Col>
            <Col>
              <span style={{ fontWeight: 600 }}>{locale.labels.amount}: </span>
              <span id='price'>
                {money(data.sale.total_billed_amount)} {currencies.uah}
              </span>
            </Col>
            <Col>
              <span style={{ fontWeight: 600 }}>{locale.labels.totalPaidAmount}: </span>
              <span id='total_paid'>
                {money(data.sale.total_payed_amount)} {currencies.uah}
              </span>
            </Col>
            <Col>
              <span style={{ fontWeight: 600 }}>{locale.labels.stockDiscount}: </span>
              <span id='stock_discount_percentage'>
                {data.sale.stock_discount.percentage || 0}%{' '}
              </span>
              <span id='stock_discount_amount'>
                ({data.sale.stock_discount.amount || 0} {currencies.uah})
              </span>
            </Col>
            <Col>
              <span style={{ fontWeight: 600 }}>{locale.labels.remainingAmount}: </span>
              <span id='remaining_amount'>
                {money(data?.sale.remaining_amount)} {currencies.uah}
              </span>
            </Col>
          </Col>
          <Col span={24} md={12} className='appointment-details-top-container__right-column'>
            <Col>
              {!!data?.sale?.client?.name && (
                <>
                  <span style={{ fontWeight: 600 }}>{locale.labels.client}: </span>
                  <span id='client'>{data?.sale?.client?.name}</span>
                </>
              )}
            </Col>
            <Col>
              {!!data?.client?.phone_number && (
                <>
                  <span style={{ fontWeight: 600 }}>{locale.labels.clientPhone}: </span>
                  <span id='client_phone_number'>{`+${data?.client?.phone_number}`}</span>
                </>
              )}
            </Col>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col span={24} md={12} className='appointment-details-top-container__left-column'>
          <Col>
            <span style={{ fontWeight: 600 }}>{locale.labels.date}: </span>
            <span id='date'>{localDateTime(data?.date)}</span>
          </Col>
          <Col>
            {!!data?.final_amount && (
              <>
                <span style={{ fontWeight: 600 }}>{locale.labels.amount}: </span>
                <span id='price'>
                  {money(data.final_amount)} {currencies.uah}
                </span>
              </>
            )}
          </Col>
          <Col>
            {!!data?.total_paid_amount && (
              <>
                <span style={{ fontWeight: 600 }}>{locale.labels.totalPaidAmount}: </span>
                <span id='total_paid'>
                  {money(data.total_paid_amount)} {currencies.uah}
                </span>
              </>
            )}
          </Col>
          <Col>
            {!!data?.services_discount?.percentage && (
              <>
                <span style={{ fontWeight: 600 }}>{locale.labels.servicesDiscount}: </span>
                <span id='services_discount_percentage'>{data.services_discount.percentage}% </span>
                <span id='services_discount_amount'>
                  ({data.services_discount.amount}
                  {currencies.uah})
                </span>
              </>
            )}
          </Col>
          <Col>
            {!!data?.stock_discount?.percentage && (
              <>
                <span style={{ fontWeight: 600 }}>{locale.labels.stockDiscount}: </span>
                <span id='stock_discount_percentage'>{data.stock_discount.percentage}% </span>
                <span id='stock_discount_amount'>
                  ({data.stock_discount.amount}
                  {currencies.uah})
                </span>
              </>
            )}
          </Col>
          <Col>
            <>
              <span style={{ fontWeight: 600 }}>{locale.labels.remainingAmount}: </span>
              <span id='remaining_amount'>
                {money(data?.remaining_amount)} {currencies.uah}
              </span>
            </>
          </Col>
        </Col>
        <Col span={24} md={12} className='appointment-details-top-container__right-column'>
          <Col>
            <span style={{ fontWeight: 600 }}>{locale.labels.category}: </span>
            <span id='category'>{data?.category || '-'}</span>
          </Col>
          <Col>
            {!!data?.vat_included && (
              <>
                <span style={{ fontWeight: 600 }}>{locale.labels.vatIncluded}: </span>
                <span id='vat_included'>
                  {valueFromList(localizedYesNoBooleanOptions, data.vat_included)}
                </span>
              </>
            )}
          </Col>
          <Col>
            {!!data?.invoice && (
              <>
                <span style={{ fontWeight: 600 }}>{locale.labels.invoice}: </span>
                <span id='invoice_number'>{data.invoice.invoice_number}</span>
              </>
            )}
          </Col>
          <Col>
            {!!data?.payment_status && (
              <>
                <span style={{ fontWeight: 600 }}>{locale.labels.paymentStatus}: </span>
                <span id='status'>
                  {valueFromList(localizedPaymentStatuses, data.payment_status)}
                </span>
              </>
            )}
          </Col>
          <Col>
            <span style={{ fontWeight: 600 }}>{locale.labels.description}: </span>
            <TextEditorView value={data?.description} id='description' borderless />
          </Col>
        </Col>
      </Row>
    );
  };

  const renderBillDetailsByBillType = ({ appointment, sale, invoice }: IBillDetails): ReactNode => {
    if (appointment) {
      return (
        <div className='bill-appointment-info-block'>
          <ViewEditBill form={viewBillForm} billDetailsPage initialValues={data} />
        </div>
      );
    }
    if (sale) {
      return (
        <BillsDetailsSalesSection
          form={editBillForm}
          initialValues={data}
          clientDetails={data?.client}
        />
      );
    }
    if (invoice) {
      return (
        <>
          <h4>{locale.labels.stock}</h4>
          <ViewEditInvoiceItemsTable data={data.invoice.stock} />
        </>
      );
    }
  };

  useEffect(() => {
    if ((billsLoading && !dataLoading) || (paymentsLoading && !dataLoading)) {
      setDataLoading(true);
    } else if (!billsLoading && !paymentsLoading && dataLoading) {
      setDataLoading(false);
    }
  }, [billsLoading, paymentsLoading, dataLoading]);

  useEffect(() => {
    return () => setDataLoading(true);
  }, []);

  const onConfirmDeleteClick = () => {
    dispatch(removeBill(+clinicId, +billId,
      () => setShowDeleteModal(false)));
  };

  return (
    <>
      <PageInitialLoader loading={billsLoading || paymentsLoading}/>
      <Row>
        <Col span={24}>
          {!!data && (
            <div className={'name-statuses-form-header'}>
              <h4 style={{ marginRight: '8px' }} id='bill-name'>
                {data?.name}
              </h4>
              <span style={{ marginRight: 12 }}>{getType(getBillTypeTag(data?.bill_type))}</span>
              <StatusTag status={data?.payment_status} />
            </div>
          )}
        </Col>
      </Row>
      <Spin spinning={dataLoading} className='payments-details-page-spin-container'>
        <div style={{ borderBottom: '1px solid rgba(5, 5, 5, 0.06)' }}>
          <div className='appointment-details-top-container' style={{ marginBottom: 10 }}>
            {renderTopBillDetailsByBillType()}
          </div>
        </div>

        {!data?.deleted && <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <div style={{ visibility: !showSimpleBillModal ? 'visible' : 'hidden', padding: 10 }}>
            <ViewDetailsButtonsGroup
              hideCancelBtn
              fixed
              accessGroup={userAccess.accesses.services}
              editMode={false}
              onEditClick={() => {
                dispatch(showModal(true));
              }}
              hideDeleteBtn={!data?.can_be_destroyed}
              onDeleteClick={() => setShowDeleteModal(true)}
            />
          </div>
          <BillContentToPrintWrapper billId={billId} data={data} />
        </Row>}

        {data && renderBillDetailsByBillType(data)}

        <BillsDetailsPayments
          data={billPayments}
          locale={locale}
          currencies={currencies}
          bill={data}
          loading={billsLoading}
          billId={data?.id}
          clinicId={clinicId}
          openedShift={openedShift}
        />
      </Spin>
      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={onConfirmDeleteClick}
        onCancel={() => setShowDeleteModal(false)}
      />
      {showSimpleBillModal && <EditBill form={editBillForm} locale={locale} initialValues={data} />}
    </>
  );
};

export default BillsDetails;
