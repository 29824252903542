import '../invoices/scss/InvoicesList.scss';

import { Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { StatusTag } from 'components/StatusTag.tsx';
import StickyButton from 'components/StickyButton';
import VLXSearch from 'components/VLXSearch';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { useSearch } from 'hooks/useSearch';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  getInvoiceStockItemsById,
  indexInvoices,
  setSelectedInvoice,
  setSelectedInvoiceStockItems,
  showCreateModal,
} from 'redux/inventory/invoice/actions';
import { IInvoiceStockItem } from 'services/clinic/inventory/invoices/inventoryItems/models/showInventoryItemResponse';
import { IInvoiceDataItem } from 'services/interfaces/clinics/inventory/invoice/IInvoiceData';

import AddInvoiceModal from './AddInvoiceModal';

const InvoiceList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const userAccess = useUserAccess();
  const navigate = useNavigate();

  const locale = useLocale('private.inventory.invoices');
  const currencies = useLocale('private.currencies').labels;
  const { data, metadata } = useSelector(({ invoices }: IAppState) => invoices);
  const loading = useSelector(({ invoices }: IAppState) => invoices.loading);
  const showCreateInvoiceModal = useSelector(({ invoices }: IAppState) => invoices.showCreateModal);
  const selectedInvoiceStockItems = useSelector(
    ({ invoices }: IAppState) => invoices.selectedInvoiceStockItems?.data
  );

  const [expanded, setExpended] = useState([]);

  const { handleChange, onPageChange, filterResults } = useSearch({
    action: indexInvoices,
    clinicId,
    defaultSortKey: 'created_at',
    defaultSortOrder: 'desc'
  });

  useEffect(() => {
    dispatch(setSelectedInvoice(null));
  }, []);

  const redirectToInvoiceDetails = (record: IInvoiceDataItem) =>
    userAccess.accesses.invoices.showItem && {
      onClick: () => {
        navigate(`/clinic/${clinicId}/inventory/invoices/${record.id}`);
      }
    };

  const getProviderName = (e) => {
    if (e.provider?.name) {
      return (
        <Link
          className={'custom-links'}
          to={`/clinic/${clinicId}/inventory/providers/${e?.provider?.id}`}
        >
          {e.provider.name}
        </Link>
      );
    }
    return '-';
  };

  const columns = useMemo(
    (): TableColumnProps<IInvoiceDataItem>[] => [
      {
        key: 'purchase_date',
        title: locale.labels.date,
        sorter: () => null,
        onCell: redirectToInvoiceDetails,
        render: (e) => `${e.purchase_date ? moment(e.purchase_date).format('DD.MM.YYYY') : '-'}`
      },
      {
        key: 'invoice_number',
        title: locale.labels.invoiceNumber,
        sorter: () => null,
        onCell: redirectToInvoiceDetails,
        render: (e) => `${e.invoice_number ? e.invoice_number : '-'}`
      },
      {
        key: 'payment_status',
        sorter: () => null,
        title: locale.labels.paymentStatus,
        render: (e) => <StatusTag status={e.payment_status} />,
        onCell: redirectToInvoiceDetails
      },
      {
        key: 'total_price',
        sorter: () => null,
        title: locale.labels.totalPrice,
        render: (e) => e.total_price ? `${money(e.total_price)} ${currencies.uah}` : '-',
        onCell: redirectToInvoiceDetails
      },
      {
        key: 'remaining_amount',
        sorter: () => null,
        title: locale.labels.remainingAmount,
        render: (e) => e.remaining_amount ? `${money(e.remaining_amount)} ${currencies.uah}` : '-',
        onCell: redirectToInvoiceDetails
      },
      {
        key: 'provider_name',
        title: locale.labels.provider,
        sorter: () => null,
        onCell: redirectToInvoiceDetails,
        render: (e) => getProviderName(e)
      }
    ],
    [data]
  );

  const expandedColumns = useMemo(
    (): TableColumnProps<IInvoiceStockItem>[] => [
      {
        key: 'stock_name',
        title: locale.labels.name,
        render: (e) =>
          ListFieldWithTooltip(
            <Link
              className={'custom-links'}
              to={`/clinic/${clinicId}/inventory/stock/${e.stock.id}`}
            >
              {e.stock.name}
            </Link>
          )
      },
      {
        key: 'series',
        title: locale.labels.series,
        render: (e) => e.series || '-'
      },
      {
        key: 'quantity',
        title: locale.labels.purchaseQuantity,
        render: (e) => e.quantity || '-'
      },
      {
        key: 'price_per_unit',
        title: locale.labels.purchasePrice,
        render: (e) => `${e.price_per_unit ? `${money(e.price_per_unit)} ${currencies.uah}` : '-'}`
      },
      {
        key: 'selling_price',
        title: locale.labels.sellingPrice,
        render: (e) => `${e.selling_price ? `${money(e.selling_price)} ${currencies.uah}` : '-'}`
      }
    ],
    [selectedInvoiceStockItems]
  );

  const expandedRowRender = useCallback(
    (): JSX.Element =>
      selectedInvoiceStockItems ? (
        <Table
          rowKey='id'
          columns={expandedColumns}
          dataSource={selectedInvoiceStockItems}
          pagination={false}
          size='small'
          className='invoice-items-table'
          bordered
          rowClassName={(record) => (record.deleted ? 'disabled-row' : '')}
        />
      ) : (
        <Spin
          style={{
            display: 'block',
            padding: 8
          }}
        />
      ),
    [selectedInvoiceStockItems]
  );

  return (
    <>
      <VLXSearch filterResults={filterResults} />
      <Spin spinning={loading}>
        <Table
          pagination={false}
          className='accent-header invoices-list'
          rowKey='id'
          dataSource={data}
          onChange={handleChange}
          columns={columns}
          expandable={{
            onExpand: (expanded, record: IInvoiceDataItem) => {
              dispatch(setSelectedInvoiceStockItems(null));
              dispatch(getInvoiceStockItemsById(clinicId, record.id));
              const current = expanded ? [record.id] : [];
              setExpended(current);
            },
            expandedRowKeys: expanded,
            expandedRowRender
          }}
        />
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center'
          }}
        >
          <Pagination
            className={'equipment-pagination'}
            defaultCurrent={1}
            current={metadata.page}
            showSizeChanger={false}
            showLessItems
            pageSize={20}
            total={metadata.total}
            onChange={onPageChange}
          />
          <StickyButton
            offsetBottom={10}
            onClick={() => dispatch(showCreateModal(true))}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={locale.buttons.createInvoice}
          />
          {showCreateInvoiceModal && <AddInvoiceModal clinicId={clinicId} />}
        </Row>
      </Spin>
    </>
  );
};

export default InvoiceList;
