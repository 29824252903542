import { Col, Row } from 'antd';
import moment from 'moment';
import { TimeProps } from 'pages/calendar/types';

export const getTime = ({ start_time, end_time }: TimeProps): JSX.Element => {
  const startTime = moment(start_time);
  const endTime = moment(end_time);
  const isTodaysDate = startTime.day() === endTime.day();

  if (isTodaysDate) {
    return (
      <Col className='time-cell-row-container'>
        <Row className='time-cell-row'>
          <span className='icofont-ui-calendar'></span>
          {startTime.format('DD MMM')}
        </Row>
        <Row className='time-cell-row'>
          <span className='icofont-clock-time'></span>
          {startTime.format('HH:mm')}-{endTime.format('HH:mm')}
        </Row>
      </Col>
    );
  }

  return (
    <Col className='time-cell-row-container'>
      <Row className='time-cell-row'>
        <span className='icofont-ui-calendar'></span>
        {startTime.format('DD MMM HH:mm')}
      </Row>
      <Row className='time-cell-row'>
        <span className='icofont-ui-calendar'></span>
        {endTime.format('DD MMM HH:mm')}
      </Row>
    </Col>
  );
};

export const getDateMonthTime = ({ start_time, end_time }: TimeProps): string => {
  const formattedStart = moment(start_time).format('D MMM');
  const formattedEnd = moment(end_time).format('D MMM');

  return `${formattedStart} - ${formattedEnd}`;
};
