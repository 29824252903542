import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import React, { useState } from 'react';
import { ISelectedStockPrices } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';

import { PrintMultipleStocksForm } from './PrintMultipleStocksForm';
import { PrintPricesForm } from './PrintPricesForm';

type Props = {
  setShowPrintPricesModal: (show: boolean) => void;
  stock: any;
  prices?: Array<ISelectedStockPrices>;
  multipleStocksMode?: boolean;
  showChooseNewStockBtn?: boolean;
};

export const PrintPricesModal = ({
  setShowPrintPricesModal, prices, stock, multipleStocksMode, showChooseNewStockBtn
}: Props): JSX.Element => {
  const userAccess = useUserAccess();
  const [form] = Form.useForm();
  const locale = useLocale('public.modals.printPrices');

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onConfirmCancelClick = () => {
    form.resetFields();
    setShowCancelModal(false);
    setShowPrintPricesModal(false);
  };

  const modalButtons = () => {
    return <ViewDetailsButtonsGroup
      accessGroup={userAccess.accesses.invitations}
      fixed
      editMode
      submitButtonIcon={'icofont-print'}
      hideDeleteBtn
      onCancelClick={() => {
        if (form.isFieldsTouched()) {
          setShowCancelModal(true);
        } else {
          setShowPrintPricesModal(false);
        }
      }}
      onDeleteClick={() => {
        setShowCancelModal(false);
      }}
    />;
  };

  return (
    <VLXModal
      title={locale.labels.pricePrint}
      centered
      open
      width={1100}
      form={form}
      onModalClose={() => setShowPrintPricesModal(false)}
    >
      {multipleStocksMode ? 
        <PrintMultipleStocksForm
          stockData={stock}
          form={form}
          showChooseNewStockBtn={showChooseNewStockBtn}
          locale={locale}
        >
          {modalButtons()}
        </PrintMultipleStocksForm> : 
        <PrintPricesForm
          prices={prices}
          stock={stock}
          form={form}
          locale={locale}
        >
          {modalButtons()}
        </PrintPricesForm>
      }
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
