import dayjs from 'dayjs';
import React, { useState } from 'react';

import ConfirmCancelModal from '../../../components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from '../../../components/ViewDetailsButtonsGroup';
import VLXModal from '../../../components/VLXModal';
import { useLocale } from '../../../hooks/useLocale';
import { useUserAccess } from '../../../hooks/useUserAccess';
import AddVaccinationForm from './AddVaccinationForm';

const AddVaccination = ({ initialValues, form, closeVaccinationModal, showModal, editMode, patientId, callbackFn, showPatientSelect }: any) => {
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();
  const [formEditMode, setFormEditMode] = useState<boolean>(false);
  const locale = useLocale('private.patients.patient-page');

  const onConfirmCancelClick = () => {
    form.resetFields();
    setFormEditMode(false);
    setShowCancelModal(false);
    closeVaccinationModal();
  };

  const onFieldsChange = (e: any) => {
    if (e.length < 2 && !formEditMode) {
      setFormEditMode(true);
    }
  };

  const mapValues = (data) => {
    if (data && editMode) {
      const res = { ...data };
      if (data.expiration_date) {
        res.expiration_date = dayjs(data.expiration_date);
      }
      if (data.date_of_vaccination) {
        res.date_of_vaccination = dayjs(data.date_of_vaccination);
      }
      if (data.date_of_next_vaccination) {
        res.date_of_next_vaccination = dayjs(data.date_of_next_vaccination);
      }
      return res;
    }
  };

  return (
    <>
      <VLXModal
        title={editMode ? locale.labels.editVaccination : locale.labels.createVaccination}
        centered={true}
        form={form}
        open={showModal}
        onModalClose={() => { closeVaccinationModal(); setFormEditMode(false); }}
        width={800}
      >
        {showModal && <AddVaccinationForm callbackFn={callbackFn} form={form} onFieldsChange={onFieldsChange}
        setFormEditMode={setFormEditMode} closeVaccinationModal={closeVaccinationModal} initialValues={mapValues(initialValues)}
        patientId={patientId} editMode={editMode} showPatientSelect={showPatientSelect}>
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.patients}
            editMode={editMode ? formEditMode : true}
            onEditClick={() => setFormEditMode(true)}
            hideDeleteBtn={true}
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                setFormEditMode(false);
                closeVaccinationModal();
              }
            }}
            />
        </AddVaccinationForm>}
        <ConfirmCancelModal open={showCancelModal} onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)} />
      </VLXModal>
    </>
  );
};

export default AddVaccination;
