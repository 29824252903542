import { Spin } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'redux/finances/payments/actions';
import { IPaymentsListState } from 'redux/finances/payments/reducer';

import AddPaymentForm from './AddPaymentForm';

const AddPayment = (
  {
    hideDefaultCreateBtn,
    initialValues,
    form,
    openedShift,
    callbackFunc
  }: any): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const locale = useLocale('private.finances.payments');

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const payments = useSelector<IAppState, IPaymentsListState>(({ payments }) => payments);

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      {!hideDefaultCreateBtn && (
        <StickyButton
          offsetBottom={10}
          onClick={() => dispatch(showModal(true))}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addPayment}
          disabled={!openedShift}
          tooltip={!openedShift ? locale.labels.paymentBlockedClosedShift : null}
        />
      )}
      <VLXModal
        title={locale.labels.addPayment}
        centered={true}
        form={form}
        open={payments.showModal}
        onModalClose={() => dispatch(showModal(false))}
        width={800}
      >
        <Spin spinning={payments?.loading}>
          {payments.showModal && (
            <AddPaymentForm locale={locale} form={form} initialValues={initialValues} callbackFunc={callbackFunc}>
              <ViewDetailsButtonsGroup
                fixed
                accessGroup={userAccess.accesses.financialAccounts}
                editMode={true}
                hideDeleteBtn={true}
                onCancelClick={() => {
                  if (form.isFieldsTouched()) {
                    setShowCancelModal(true);
                  } else {
                    dispatch(showModal(false));
                  }
                }}
                onDeleteClick={() => {
                  setShowCancelModal(false);
                }}
              />
            </AddPaymentForm>
          )}
        </Spin>
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default AddPayment;
