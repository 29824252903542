import { Form, Spin } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showPaymentsModal } from 'redux/finances/bills/actions';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';

import { ShowPaymentResponse } from 'services/clinic/payments/models/ShowPaymentResponse';
import { AddBillPaymentForm } from './AddBillPaymentForm';

type Props = {
  bill: IBillDetails;
  openedShift: boolean;
  hideAddButton?: boolean;
  onBillPaymentCallback?: (data: ShowPaymentResponse) => void;
};

export const AddBillPayment = (
  {
    bill,
    onBillPaymentCallback,
    hideAddButton,
    openedShift
  }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const locale = useLocale('private.finances.bills');
  const [form] = Form.useForm();

  const bills = useSelector(({ bills }: IAppState) => bills);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(showPaymentsModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      {!hideAddButton && (
        <StickyButton
          offsetBottom={10}
          onClick={() => dispatch(showPaymentsModal(true))}
          disabled={!openedShift}
          tooltip={!openedShift ? locale.labels.paymentBlockedClosedShift : null}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addPayment}
        />
      )}
      {bills.showPaymentsModal && (
        <VLXModal
          title={locale.labels.createPayment}
          centered
          form={form}
          open={bills.showPaymentsModal}
          onModalClose={() => dispatch(showPaymentsModal(false))}
          width={800}
        >
          <Spin spinning={bills?.loading}>
            {bills.showPaymentsModal && (
              <AddBillPaymentForm
                locale={locale}
                form={form}
                bill={bill}
                onBillPaymentCallback={onBillPaymentCallback}
              >
                <ViewDetailsButtonsGroup
                  fixed
                  accessGroup={userAccess.accesses.bills}
                  editMode
                  hideDeleteBtn
                  onCancelClick={() => {
                    if (form.isFieldsTouched()) {
                      setShowCancelModal(true);
                    } else {
                      dispatch(showPaymentsModal(false));
                    }
                  }}
                  onDeleteClick={() => {
                    setShowCancelModal(false);
                  }}
                />
              </AddBillPaymentForm>
            )}
          </Spin>
          <ConfirmCancelModal
            open={showCancelModal}
            onOk={onConfirmCancelClick}
            onCancel={() => setShowCancelModal(false)}
          />
        </VLXModal>
      )}
    </>
  );
};
