import { IEmployee } from 'interfaces/employee';
import { ILocale } from 'interfaces/locale.ts';

export const getInitials = (fullName: string): string => {
  const nameParts = fullName?.split(' ').filter(Boolean);
  if (nameParts.length === 0) return '';
  const firstInitial = nameParts[0].charAt(0).toUpperCase();
  const lastInitial =
    nameParts.length > 1 ? nameParts[nameParts.length - 1].charAt(0).toUpperCase() : '';
  return firstInitial + lastInitial;
};

export const getFirstAndLastName = (fullName: string): string => {
  const words = fullName.split(' ').filter(Boolean);
  if (words.length > 2) {
    return `${words[0]} ${words[words.length - 1]}`;
  }
  return fullName;
};

export const getEmployeeName = (locale: ILocale, item: IEmployee, userId: number): string => {
  if (item.id === userId) {
    return locale.labels.myEvents;
  }
  if (item.title) return `${item.full_name} (${item.title})`;
  return `${item.full_name}`;
};
