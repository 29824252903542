export const redirectURL = 'redirectURL';

export const animalIdUrl = 'https://animal-id.net/en/animal/search?search=';

export const eventsColors = [
  '#5A9BD5', // Royal Blue
  '#FF6F61', // Coral Red
  '#6B5B95', // Deep Purple
  '#88C0D0', // Sky Blue
  '#F7CAC9', // Blush Pink
  '#92A8D1', // Periwinkle
  '#034f84', // Navy Blue
  '#FFA07A', // Light Salmon
  '#20B2AA', // Light Sea Green
  '#FFB6C1', // Light Pink
  '#4682B4', // Steel Blue
  '#D4A5A5', // Dusty Rose
  '#C85C5C', // Burnt Red
  '#A3C4C9', // Misty Teal
  '#F5D98E', // Pale Mustard
  '#9E858F', // Vintage Rose
  '#C89F91', // Warm Beige
  '#88B398', // Sage Green
  '#8DA9C4', // Slate Blue
  '#D7A89E', // Peachy Coral
  '#D5A26C', // Warm Camel
  '#D8C59A', // Sandstone
  '#BFD8B7', // Olive Mist
  '#92B8C6', // Steel Blue
  '#A3B3C2', // Cool Grey
  '#BACBD8', // Frost Blue
  '#D3C0D2', // Lilac Mist
  '#EAD8E5', // Soft Lavender
  '#F4B183', // Apricot
  '#A7C957' // Lime Green
];

export const languagesList = [
  {
    searchName: 'en',
    antdName: 'enGB'
  },
  {
    searchName: 'uk',
    antdName: 'ukUA'
  }
];

// TODO: set all date/time formats
export const dateFormat = '';

export const DATE_TIME_FORMAT = 'DD.MM.YYYYTHH:mm';
export const DATE_TIME_VIEW_FORMAT = 'DD.MM.YYYY HH:mm';

export const DATE_TIME_WITH_ZONE = 'YYYY-MM-DD HH:mm [GMT]Z';
export const DATE_FORMAT = 'DD.MM.YYYY';

export const timeFormat = '';

export const statusColors = {
  future: '#87d068',
  closed: '#f50'
};

export const roomStatusColor = {
  active: 'success',
  maintenance: 'processing',
  closed: 'error'
};

export const enterKeyCode = 13;
