import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import { TextEditor } from 'components/TextEditor.tsx';
import VLXInputNumber from 'components/VLXInputNumber';
import dayjs from 'dayjs';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import { ILocale } from 'interfaces/locale';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createBillPayment } from 'redux/finances/bills/actions';
import { getAccounts } from 'redux/finances/financialAccounts/actions';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';
import { ShowPaymentResponse } from 'services/clinic/payments/models/ShowPaymentResponse.ts';
import { requiredRule, validatorRule } from 'utils/form-rules';

const { Option } = Select;

type Props = React.PropsWithChildren<{
  bill: IBillDetails;
  form: FormInstance;
  locale?: ILocale;
  onBillPaymentCallback?: (data: ShowPaymentResponse) => void;
}>;

export const AddBillPaymentForm = (
  {
    form,
    children,
    locale,
    bill,
    onBillPaymentCallback
  }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const { appointmentId } = useParams();

  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [receivedFromClient, setReceivedFromClient] = useState(null);
  const [paidPerBill, setPaidPerBill] = useState(bill?.remaining_amount);
  const [putOnClientBalance, setPutOnClientBalance] = useState(null);
  const [remaining, setRemaining] = useState(null);
  const outcomePayment = bill.bill_type === 'outcome';

  const currencies = useLocale('private.currencies').labels;

  const accountsData = useSelector(({ financialAccounts }: IAppState) => financialAccounts.data);

  useEffect(() => {
    if (clinicId) {
      dispatch(getAccounts(clinicId, 0));
    }
  }, [clinicId]);

  useEffect(() => setPaidPerBill(bill.remaining_amount), [bill]);

  useEffect(() => {
    form.resetFields();
  }, []);

  const accountsList = accountsData.map((item) => {
    const accNumber = item?.account_number ? `, ${item?.account_number}` : '';
    return (
      <Option key={item.name} title={item.name} value={item.id}>
        {item?.name}
        {accNumber}
      </Option>
    );
  });

  const onSubmit = (data: any): void => {
    const apiData = {
      ...data,
      amount: paidPerBill,
      from_client_balance: paymentMethod === 'balance',
      to_balance_amount: putOnClientBalance ?? 0
    };
    dispatch(createBillPayment(clinicId, +appointmentId, bill.id, apiData, onBillPaymentCallback));
  };

  const paymentOptions =
    <>
      <Option key='cash' title={locale.labels.cash} value='cash'>
        {locale.labels.cash}
      </Option>
      <Option key='balance' title={locale.labels.balance} value='balance' disabled={!bill?.client?.balance}>
        {locale.labels.balance} ({money(bill?.client?.balance)} {currencies.uah})
      </Option>
    </>;

  const onReceivedFromClientValueChange = (newValue: number) => {
    const paidPerBillValue = newValue > bill.remaining_amount ? bill.remaining_amount : newValue;
    setReceivedFromClient(newValue);
    setPaidPerBill(paidPerBillValue);
    setRemaining(newValue - paidPerBillValue);
  };

  const onPaidPerBillChange = (newValue: number) => {
    setPaidPerBill(newValue);
    setRemaining(receivedFromClient - newValue);
  };

  const setPutOnClientBalanceValue = (newValue: number) => {
    setPutOnClientBalance(newValue);
    setRemaining(receivedFromClient - newValue - paidPerBill);
  };

  return (
    <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
      <Form.Item name='clinic_id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name={'date'} initialValue={dayjs()} label={locale.labels.date}>
        <DatePicker format='YYYY-MM-DD' />
      </Form.Item>
      {!outcomePayment && bill?.client &&
        <Form.Item rules={[requiredRule()]} label={locale.labels.payment} initialValue={paymentMethod}>
          <Select id={'payment-options'} onChange={setPaymentMethod} value={paymentMethod}>{paymentOptions}</Select>
        </Form.Item>
      }
      {paymentMethod === 'balance' &&
        <>
          <Form.Item
            label={locale.labels.amount}
            name={'amount'}
            initialValue={bill.remaining_amount}
            rules={[
              requiredRule(),
              validatorRule(
                (value) => value ? Number(value) <= bill.remaining_amount : true,
                locale.labels.sumCannotBeMoreThan
              ),
              validatorRule(
                (value) => value ? (Number(value) <= bill?.client?.balance) : true,
                locale.labels.sumCannotBeMoreThanClientBalance
              )
            ]}
          >
            <VLXInputNumber id={'amount'} value={paidPerBill} onChange={setPaidPerBill} />
          </Form.Item>
        </>
      }
      {(outcomePayment || paymentMethod === 'cash') &&
        <Form.Item
          className='add-equipment-form__multi-select-field'
          label={locale.labels.account}
          rules={[requiredRule()]}
          name='account_id'
        >
          <Select
            filterOption={(input, option) => {
              return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
            }}
            showSearch
          >
            {accountsList}
          </Select>
        </Form.Item>
      }
      {((outcomePayment && paymentMethod === 'cash') || !bill?.client) &&
        <Form.Item
          label={locale.labels.amount}
          name={'amount'}
          initialValue={bill.remaining_amount}
          rules={[
            requiredRule(),
            validatorRule(
              (value) => value ? Number(value) <= bill.remaining_amount : true,
              locale.labels.sumCannotBeMoreThan
            )
          ]}
        >
          <VLXInputNumber id={'amount'} value={paidPerBill} onChange={setPaidPerBill} />
        </Form.Item>
      }
      {(!outcomePayment && paymentMethod === 'cash' && bill?.client) &&
        <>
          <Row gutter={[10, 0]}>
            <Col span={12}>
              <Form.Item
                label={locale.labels.receivedFromClient}
                rules={[requiredRule()]}
              >
                <VLXInputNumber id={'received-from-client'} value={receivedFromClient}
                                onChange={onReceivedFromClientValueChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={locale.labels.paidPerBill}
                name='paidPerBill'
                initialValue={bill.remaining_amount}
                rules={[
                  requiredRule(),
                  validatorRule(
                    (value) => value ? Number(value) <= bill.remaining_amount : true,
                    locale.labels.sumCannotBeMoreThan
                  ),
                  validatorRule(
                    (value) => value ? Number(value) <= (receivedFromClient - putOnClientBalance) : true,
                    locale.labels.sumCannotBeMoreThanPayedByClient
                  )
                ]}
              >
                <VLXInputNumber id={'paid-per-bill'} value={paidPerBill} onChange={onPaidPerBillChange}
                                max={receivedFromClient} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={locale.labels.putOnClientBalance}
                name='to_balance_amount'
              >
                <VLXInputNumber id={'put-on-balance'} value={putOnClientBalance} onChange={setPutOnClientBalanceValue}
                                max={receivedFromClient - paidPerBill} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='end' align='middle'>
            <i className='icofont-calculator-alt-2' style={{ marginRight: 5 }}></i>
            <span style={{ fontWeight: 'bold' }}>{locale.labels.remaining}: <span
              id={'remaining-value'}>{money(remaining > 0 ? remaining : 0)}</span> {currencies.uah}</span>
          </Row>
        </>
      }
      <TextEditor label={locale.labels.notes} name={['notes']} rows={2} />
      {children}
    </Form>
  );
};
