import './scss/VLXSearch.scss';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Row, Select } from 'antd';
import { DATE_TIME_WITH_ZONE, enterKeyCode } from 'constants/common';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLinkParams from 'hooks/useSearchParams';
import React, { useEffect, useRef, useState } from 'react';
import { IFilterResultsParams } from '../hooks/useSearch.ts';

const { Option } = Select;
const { RangePicker } = DatePicker;
dayjs.extend(utc);
dayjs.extend(timezone);

type Props = {
  selectOptions?: { label: string; value: string }[];
  selectPlaceholder?: string;
  enableFilteringByDate?: boolean;
  savedFiltersName?: string;
  filterResults: (params: IFilterResultsParams) => void;
  renderAdditionalContent?: JSX.Element;
  dataLoading?: boolean;
};

const VLXSearch = (
  {
    selectOptions,
    selectPlaceholder,
    enableFilteringByDate = false,
    savedFiltersName,
    filterResults,
    renderAdditionalContent,
    dataLoading
  }: Props): JSX.Element => {
  const clinicId = useClinicId();
  const filterParams = useLinkParams('filter');
  const selectParams = useLinkParams('statuses');
  const startDateFromParams = useLinkParams('startDateFrom');
  const startDateToParams = useLinkParams('startDateTo');
  const locale = useLocale('private.composite-search');
  const selectRef = useRef(null);

  const currentDate = dayjs();
  const [dateFrom, setDateFrom] =
    useState<dayjs.Dayjs>(enableFilteringByDate ? currentDate : null);
  const [dateTo, setDateTo] =
    useState<dayjs.Dayjs>(enableFilteringByDate ? currentDate : null);
  const [searchValue, setSearchValue] =
    useState<string>(filterParams);
  const [selectValue, setSelectValue] =
    useState<string[]>([]);
  const [allEventsPressed, setAllEventsPressed] =
    useState<boolean>(false);
  const [todayPressed, setTodayPressed] =
    useState<boolean>(true);


  useEffect((): void => {
    setSearchValue(filterParams);
    setSelectValue(selectParams?.split(','));
    let fromDate: dayjs.Dayjs = null;
    let toDate: dayjs.Dayjs = null;
    if (enableFilteringByDate) {
      fromDate = startDateFromParams ? dayjs(startDateFromParams, DATE_TIME_WITH_ZONE) : currentDate;
      toDate = startDateToParams ? dayjs(startDateToParams, DATE_TIME_WITH_ZONE) : currentDate;
      setDateFrom(fromDate);
      setDateTo(toDate);
    }

    getResults(filterParams, fromDate, toDate, selectParams);
  }, [savedFiltersName]);

  useEffect(() => {
    return (): void => {
      setSearchValue(null);
      setSelectValue([]);
      setDateFrom(null);
      setDateTo(null);
    };
  }, []);

  const onKeyDown = (event): void => {
    if (event.keyCode === enterKeyCode) {
      onSearch();
    }
  };

  const getResults = (filterParam: string,
                      startDateFromParam: dayjs.Dayjs,
                      startDateToParam: dayjs.Dayjs,
                      statusesParam: string | string[]): void => {
    filterResults({
      filter: filterParam,
      startDateFrom: startDateFromParam
        ?.hour(0).minute(0).local().tz().format(DATE_TIME_WITH_ZONE),
      startDateTo: startDateToParam
        ?.hour(23).minute(59).local().tz().format(DATE_TIME_WITH_ZONE),
      statuses: statusesParam
    });
  };

  const onSearch = (): void => {
    getResults(searchValue, dateFrom, dateTo, selectValue);
    setLocalStorageFilters();
  };

  const onSelectValueChange = (vals: string[]): void => {
    setSelectValue(vals.filter((el) => el));
  };

  const selectOptionsFormatted: JSX.Element[] = selectOptions?.map(
    (item): JSX.Element => (
      <Option key={item.value} title={item.label} value={item.value}>
        {item.label}
      </Option>
    )
  );

  const onResetFilters = (): void => {
    const filters = JSON.parse(localStorage.getItem(savedFiltersName));
    if (filters) {
      for (const key in filters) {
        if (+key === +clinicId) {
          filters[clinicId] = null;
        }
      }
      localStorage.setItem(savedFiltersName, JSON.stringify(filters));
    }

    setDateFrom(currentDate);
    setDateTo(currentDate);
    setSearchValue('');
    setSelectValue([]);
    setTodayPressed(true);
    setAllEventsPressed(false);
    const fromDate: dayjs.Dayjs = enableFilteringByDate ? currentDate : null;
    const toDate: dayjs.Dayjs = enableFilteringByDate ? currentDate : null;
    getResults('', fromDate, toDate, '');
  };

  const setLocalStorageFilters = (): void => {
    const filters = JSON.parse(localStorage.getItem(savedFiltersName));
    if (filters) {
      filters[clinicId] = { searchValue, selectValue };
      localStorage.setItem(savedFiltersName, JSON.stringify(filters));
    } else {
      localStorage.setItem(
        savedFiltersName,
        JSON.stringify({ [clinicId]: { searchValue, selectValue } })
      );
    }
  };

  const onRangeChange = (data: dayjs.Dayjs[]): void => {
    if (data?.length) {
      const dateFrom = data[0];
      const dateTo = data[1];
      setDateFrom(dateFrom);
      setDateTo(dateTo);
      getResults(searchValue, dateFrom, dateTo, selectValue);
      setTodayPressed(false);
      setAllEventsPressed(false);
    } else {
      setDateFrom(null);
      setDateTo(null);
      getResults(searchValue, null, null, selectValue);
      setTodayPressed(false);
      setAllEventsPressed(true);
    }

  };

  const getDateLabel = (): string => {
    const from = dateFrom?.format('DD MMM YYYY');
    const to = dateTo?.format('DD MMM YYYY');
    if (!from && !to) return '';
    if (from === to) return from;
    return `${from} - ${to}`;
  };

  const todayClick = (): void => {
    setDateFrom(currentDate);
    setDateTo(currentDate);
    setTodayPressed(true);
    setAllEventsPressed(false);
    getResults(searchValue, currentDate, currentDate, selectValue);
  };

  const allTimeClick = (): void => {
    setDateFrom(null);
    setDateTo(null);
    setTodayPressed(false);
    setAllEventsPressed(true);
    getResults(searchValue, null, null, selectValue);
  };

  return (
    <div>
      <Row
        className='composit-search-select-option-search'
        justify={enableFilteringByDate ? 'end' : 'start'}>
        <Input
          className='search-field composit-search-select-option-search-input'
          allowClear
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          onKeyDown={onKeyDown}
          style={{ margin: 0 }}
          placeholder={locale.placeholders.searchCriteria}
        />
        {selectOptions && (
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            className='composit-search-select-option-search-select'
            placeholder={selectPlaceholder}
            mode='multiple'
            showSearch={false}
            onChange={onSelectValueChange}
            value={selectValue?.filter((el) => el)}
            ref={selectRef}
          >
            {selectOptionsFormatted}
          </Select>
        )}
        <Button
          className='composit-search-select-option-search-button'
          type='primary'
          onClick={onSearch}
          disabled={dataLoading}
        >
          {locale.buttons.search}
        </Button>
        <Button
          className='composit-search-select-option-reset-button'
          disabled={!searchValue && !selectValue?.length && !dateFrom && !dateTo}
          icon={<CloseCircleOutlined style={{ margin: '0px' }} />}
          type='primary'
          onClick={onResetFilters}
        >
          {locale.buttons.resetFilters}
        </Button>
        {renderAdditionalContent}
      </Row>
      {enableFilteringByDate && (
        <Row
          className='composit-search-select-option-search composit-search-select-option-search__date'
          justify='space-between'>
          <div style={{ fontWeight: '600', fontSize: 17 }}>{getDateLabel()}</div>
          <Row style={{ gap: 20 }}>
            <RangePicker
              format={'DD-MM-YYYY'}
              onChange={onRangeChange}
              value={dateFrom && dateTo ? [dateFrom, dateTo] : null}
            />
            <Button
              className={todayPressed
                ? 'composit-search-time-range-button-selected'
                : 'composit-search-time-range-button'}
              disabled={dataLoading}
              type='primary'
              onClick={todayClick}
            >
              {locale.buttons.today}
            </Button>
            <Button
              className={allEventsPressed
                ? 'composit-search-time-range-button-selected'
                : 'composit-search-time-range-button'}
              disabled={dataLoading}
              type='primary'
              onClick={allTimeClick}
            >
              {locale.buttons.allTime}
            </Button>
          </Row>
        </Row>
      )}
    </div>
  );
};

export default VLXSearch;
