import { getSocketConnection } from './SocketConnection';
import { setShiftState } from '../redux/common/actions';
import { Subscription } from '@rails/actioncable';

let shiftsChannel: Subscription = null;

export const consumeShiftsChannel = (clinicId: number, token: string, dispatch: Function): void => {

  shiftsChannel = getSocketConnection().subscriptions.create({channel: `ShiftsChannel`, clinic_id: clinicId, token}, {
    initialized (): void {
      console.log('ShiftsChannel initialized');
    },

    received(data): void {
      dispatch(setShiftState(data.opened));
    },

    connected (): void {
      console.log('ShiftsChannel connected');
    },
  });
};

export const disconnectShiftsChannel = (): void => {
  if (shiftsChannel) {
    shiftsChannel.unsubscribe();
    shiftsChannel = null;
    console.log('ShiftsChannel disconnected');
  }
};
