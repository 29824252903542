import '../calendar/EventsCalendar.scss';

import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Divider, Layout } from 'antd';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import { IEmployee } from 'interfaces/employee';
import { AllDayEventsModal } from 'pages/calendar/AllDayEventsModal';
import { CalendarViewType } from 'pages/calendar/types';
import { dateLocalizer } from 'pages/calendar/utils/dateLocalizer';
import { defineTimeFrameFilter } from 'pages/calendar/utils/define-time-frame-filter';
import React, { useEffect, useMemo, useState } from 'react';
import { Calendar as BigCalendar, Views } from 'react-big-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentDetails, setInitialDataForAppointment } from 'redux/appointments/actions';
import { getEventById, retrieveUserEvents } from 'redux/events/actions';
import { EventsIndexResponse } from 'services/interfaces/clinics/events/EventsIndexResponse';

import { EventModal } from '../calendar/EventModal';

const localizer = dateLocalizer();

const USER_COLOR = '#8bd1ca';

const DashboardPageCalendar = (): JSX.Element => {
  const mobile = window.innerWidth < 992;
  const dispatch = useDispatch();
  const locale = useLocale('private.calendar.calendar');
  const events = useSelector(({ events }: IAppState) => events.data);
  const selectedEventData = useSelector(({ events }: IAppState) => events.selectedEvent);
  const selectedAppointmentData = useSelector(
    ({ appointments }: IAppState) => appointments.selectedAppointment
  );
  const user = useSelector(({ user }: IAppState) => user);
  const employees = useSelector(({ employees }: IAppState) => employees.data);

  const [calendarHeight, setCalendarHeight] = useState(window.innerHeight - 350);
  const [current, setCurrent] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showAllDayEventsModal, setShowAllDayEventsModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setInitialDataForAppointment(null));
    fetchData(current, 'day');

    window.onresize = () => {
      setCalendarHeight(window.innerHeight - 369);
    };

    return () => {
      window.onresize = null;
    };
  }, []);

  const onNavigate = (date: Date, view: CalendarViewType): void => {
    setCurrent(date);
    fetchData(date, view);
  };

  const fetchData = (date: Date, view: CalendarViewType): void => {
    const filters = defineTimeFrameFilter(date, view);
    dispatch(retrieveUserEvents(filters));
  };

  const onSelectEvent = (info): void => {
    setSelectedEvent(info);
    if (info?.id && info?.resource?.event_type === 'appointment') {
      dispatch(getAppointmentDetails(info?.resource?.clinic_id, info?.resource?.appointment_id));
    } else if (
      info?.id &&
      info?.resource?.event_type === 'event' &&
      info?.resource?.location?.details !== 'unavailable'
    ) {
      dispatch(getEventById(info?.resource?.clinic_id, info.id));
    }
  };

  const getEmployeeColor = (id: number): string => {
    if (id === user.id) {
      return USER_COLOR;
    }

    return employees.find((employee: IEmployee) => employee.id === id)?.color;
  };

  const handleShowMore = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setShowAllDayEventsModal(true);
  };

  const allEvents = useMemo(
    () =>
      events?.map((item: EventsIndexResponse) => ({
        title: item.title,
        id: item.id,
        allDay: !!item.all_day,
        start: new Date(item.start_time),
        end: new Date(item.end_time),
        location: item.location,
        clinic_id: item.clinic_id,
        resource: {
          ...item,
          color: getEmployeeColor(item.user_id)
        }
      })),
    [events, user, employees]
  );

  return (
    <>
      <Layout style={{ background: 'none' }}>
        <BigCalendar
          onSelecting={() => false}
          localizer={localizer}
          culture={user.locale}
          events={allEvents}
          startAccessor='start'
          endAccessor='end'
          style={{
            height: mobile ? 500 : calendarHeight,
            minHeight: '100%'
          }}
          date={current}
          onNavigate={onNavigate}
          showMultiDayTimes={true}
          onSelectEvent={onSelectEvent}
          min={new Date(2024, 0, 1, 7, 0)}
          max={new Date(2024, 0, 1, 23, 0)}
          allDayMaxRows={3}
          selectable
          components={{
            eventWrapper: ({ event, children }) => {
              const updatedChild = React.cloneElement(children, {
                style: {
                  ...children.props.style,
                  borderLeftColor: event.resource.color
                }
              });
              return (
                <div
                  className='custom-event-wrapper'
                  onContextMenu={(e) => {
                    alert(`${event.title} is clicked.`);
                    e.preventDefault();
                  }}
                >
                  {updatedChild}
                </div>
              );
            }
          }}
          views={[Views.DAY]}
          defaultView={'day'}
          step={15}
          formats={{
            timeGutterFormat: (date, culture, localizer) =>
              localizer.format(date, 'HH:mm', culture),
            eventTimeRangeFormat: (range, culture, localizer) =>
              `${localizer.format(range.start, 'HH:mm', culture)} - ${localizer.format(
                range.end,
                'HH:mm',
                culture
              )}`,
            selectRangeFormat: (range, culture, localizer) =>
              `${localizer.format(range.start, 'HH:mm', culture)} - ${localizer.format(
                range.end,
                'HH:mm',
                culture
              )}`
          }}
          scrollToTime={new Date()}
          messages={{
            showMore: (count) => (
              <div onClick={handleShowMore}>{`+${count} ${locale.buttons.more}`}</div>
            ),
            previous: (
              <span className='ant-btn ant-btn-primary ant-btn-sm ant-btn-icon-only'>
                <CaretLeftOutlined />
              </span>
            ),
            next: (
              <span className='ant-btn ant-btn-primary ant-btn-sm ant-btn-icon-only'>
                <CaretRightOutlined />
              </span>
            ),
            yesterday: 'Yesterday',
            tomorrow: 'Tomorrow',
            today: (
              <span className='ant-btn ant-btn-primary ant-btn-sm'>{locale.buttons.today}</span>
            )
          }}
        />
      </Layout>
      {showAllDayEventsModal && (
        <AllDayEventsModal events={events} onCancel={() => setShowAllDayEventsModal(false)} />
      )}
      <Divider />
      {!!selectedEvent && (
        <EventModal
          selectedEvent={selectedEvent}
          selectedEventData={selectedEventData}
          setSelectedEvent={setSelectedEvent}
          selectedAppointmentData={selectedAppointmentData}
          locale={locale}
        />
      )}
    </>
  );
};

export default DashboardPageCalendar;
