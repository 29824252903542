import { IAppState } from 'interfaces/app-state';
import React from 'react';
import { useSelector } from 'react-redux';
import * as config from 'config/config-manager';

export const TermsAndConditions = (): JSX.Element => {
  const localeCode = useSelector(({ localeCode }: IAppState) => localeCode);

  const renderUaText = () => {
    return <>
      <p><strong>ТЕРМІНИ І УМОВИ ВИКОРИСТАННЯ ВЕТЕРИНАРНОГО ПРОГРАМНОГО ЗАБЕЗПЕЧЕННЯ &quot;Vetlyx&quot;</strong></p>
      <p><strong>1. Вступ</strong></p>
      <p>1.1. Ці терміни та умови (далі &ndash; Умови) регулюють ваше використання платформи та послуг Vetlyx
        (далі &ndash; Послуги, Сайт). Створюючи обліковий запис або користуючись Vetlyx, ви погоджуєтесь з цими Умовами.
        Якщо ви не погоджуєтесь, будь ласка, припиніть використання Vetlyx.</p>
      <p>1.2. Vetlyx належить та управляється компанією ТОВ &ldquo;Ветлікс&rdquo;, з адресою реєстрації: Україна,
        Львівська область, Воля-Добростанська, вулиця Шевченка, 58. У цій
        угоді &quot;ми&quot;, &quot;нас&quot; і &quot;наш&quot; означають Vetlyx.</p>
      <p>1.3. Ви можете користуватися Сайтом і всіма його сервісами, дотримуючись умов цієї Угоди та погоджуючись із
        Політикою конфіденційності, що розміщена за адресою <a
          href='https://www.vetlyx.com/privacy-policy'>https://www.vetlyx.com/privacy-policy</a>.</p>
      <p>&nbsp;</p>
      <p><strong>2. Визначення</strong></p>
      <p>2.1. <strong>Користувач</strong>: Будь-яка особа або організація, що використовує платформу Vetlyx, включаючи
        ветеринарів, власників тварин, персонал клінік тощо.</p>
      <p>2.2. <strong>Обліковий запис</strong>: Персоналізований доступ до Vetlyx, створений шляхом реєстрації з
        наданням особистих даних, таких як ім&apos;я, прізвище, номер телефону та електронна пошта.</p>
      <p>2.3. <strong>Ветеринар</strong>: Професіонал, зареєстрований у Vetlyx для ведення записів пацієнтів (тварин).
      </p>
      <p>2.4. <strong>Власник тварини</strong>: Особа, що використовує Vetlyx для управління записами своїх тварин або
        отримує послуги у <strong>Ветеринара</strong> (див. вище).</p>
      <p>2.5. <strong>Сайт/Послуги</strong>: Платформа Vetlyx, включаючи всі функції, контент та сервіси, доступні через
        неї.</p>
      <p>2.6. <strong>Організація:&nbsp;</strong>юридична або фізична особа, яка надає ветеринарні послуги та до якої
        Ветеринар має доступ для виконання своїх професійних обов&apos;язків (наприклад, Ветеринарна клініка,
        Грумінг-центр тощо).</p>
      <p>2.7.<strong>&nbsp;Клієнт</strong> &ndash; фізична або юридична особа, яка зареєстрована на Сайті та має намір
        або вже використовує програмне забезпечення &laquo;Vetlyx&raquo; для здійснення ветеринарної практики або
        управлінських функцій у сфері ветеринарії. Клієнт може також включати представників організацій, які
        використовують послуги та функціонал програми від імені компанії чи установи.</p>
      <p>&nbsp;</p>
      <p><strong>3. Реєстрація облікового запису</strong></p>
      <p>3.1. Для використання Vetlyx ви повинні створити обліковий запис, надавши свої дані. Реєструючись, ви
        погоджуєтеся надавати правдиву та актуальну інформацію.</p>
      <p>3.2. Ви відповідаєте за збереження конфіденційності своїх облікових даних та за всі дії, виконані з вашого
        облікового запису. Облікові записи Vetlyx призначені для особистого використання, і ви не маєте права ділитися
        ними з іншими.</p>
      <p>3.3. Якщо ви є ветеринаром або представляєте клініку, вам може знадобитися надати додаткову інформацію для
        підтвердження, таку як професійні ліцензії або сертифікати.</p>
      <p>&nbsp;</p>
      <p><strong>4. Обов&apos;язки користувача</strong></p>
      <p>4.1. Використовуючи Vetlyx, ви погоджуєтеся:</p>
      <ul>
        <li>Надавати правдиву, точну та актуальну інформацію.</li>
        <li>Використовувати Сайт лише для законних цілей і так, щоб не порушувати права інших.</li>
        <li>Як ветеринар, забезпечувати точність записів пацієнтів і дотримуватися належного рівня їх захисту.</li>
        <li>Не ділитися своїм обліковим записом з іншими або надавати доступ третім особам.</li>
      </ul>
      <p>4.2. <strong>Обробка даних клієнтів</strong>: Якщо ветеринар додає дані клієнта в систему, він
        зобов&apos;язується отримати усну згоду від клієнта на обробку та використання його персональних даних у системі
        Vetlyx. Ветеринар повинен чітко пояснити клієнту, як і з якою метою ці дані будуть використовуватися.</p>
      <p>4.3. Забороняється:</p>
      <ul>
        <li>Порушувати будь-які чинні закони чи нормативно-правові акти.</li>
        <li>Ділитися особистою або медичною інформацією про інших осіб без їх згоди.</li>
        <li>Ділитися доступом до свого облікового запису з третіми особами.</li>
      </ul>
      <p>&nbsp;</p>
      <p><strong>5. Право власності на контент та його управління</strong></p>
      <p>5.1. Vetlyx не несе відповідальності за контент, створений ветеринарами чи іншими учасниками в межах платформи,
        включаючи медичні записи та дані пацієнтів.</p>
      <p>5.2. Заборонено використання спеціальних програмних засобів або програм для сканування і завантаження будь-яких
        матеріалів, розміщених на Сайті. При використанні таких програмних продуктів користувач або його акаунт може
        бути заблокований без обмеження за часом або видалений без можливості відновлення.</p>
      <p>&nbsp;</p>
      <p><strong>6. Підписки та плати</strong></p>
      <p>6.1. Vetlyx пропонує безкоштовну версію з основними функціями:</p>
      <ul>
        <li>Адміністрування організації</li>
        <li>Створення профілів клієнтів та пацієнтів</li>
        <li>Реєстрація даних про вакцинації та отримання звіту про вакцинації за період</li>
        <li>Робота з календарем та планувальником візитів</li>
        <li>Реєстрація послуг організації</li>
      </ul>
      <p>Vetlyx надає можливість користувачам перейти на плани Advanced та Advanced + для доступу до додаткових
        функцій&nbsp;</p>
      <ul>
        <li>Advanced: Документи</li>
        <li>Advanced: Cклад та управління ним</li>
        <li>Advanced: Фінансовий модуль</li>
        <li>Advanced: Магазин</li>
        <li>Advanced+: Аналітика, звіти та спеціалізовані інструменти.</li>
      </ul>
      <p>6.3. Оплата підписки здійснюється за кожного окремого користувача, якому наданий доступ до системи в межах
        конкретної Організації</p>
      <p>6.4. Платежі за плани Advanced та Advanced+ здійснюються через інтегровані сервіси третіх сторін для обробки
        платежів. Підписуючись на ці плани, ви погоджуєтеся з умовами оплати, зазначеними під час підписки.</p>
      <p>6.5. Vetlyx не несе відповідальності за будь-які помилкові платежі, які сталися внаслідок надання користувачем
        неправильних платіжних даних або використання несанкціонованих способів оплати.</p>
      <p>6.6. Vetlyx має право в односторонньому порядку змінювати умови тарифних планів (Тарифів), включаючи ціни на
        Послуги.</p>
      <p>Якщо Клієнт не згоден із змінами тарифного плану (Тарифів) і не сплачує подальші платежі або ж перестає
        (припиняє) оплачувати Послуги з будь-яких інших причин, Договір вважається розірваним з ініціативи Клієнта в
        односторонньому порядку через 30 (тридцять) календарних днів після закінчення терміну доступу
        до &laquo;Vetlyx&raquo; згідно з умовами попереднього тарифного плану (Тарифу).</p>
      { !config.disabledForProd && (
        <p>6.7. З цінами та тарифами можна ознайомитись за посиланням <a
        href='https://vetlyx.com/pricing'>https://vetlyx.com/pricing</a>&nbsp;</p>
      )}
      <p>6.8. Повернення</p>
      <p>Клієнти можуть отримати повернення або відшкодування за програмне забезпечення &laquo;Vetlyx&raquo; відповідно
        до цих умов, якщо продукт не відповідає опису.</p>
      <p>Повернення можливе протягом 14 (чотирнадцяти) днів з моменту покупки лише за умови, що програмне забезпечення
        не було активовано та використано, або якщо були виявлені критичні помилки, які не дозволяють використовувати
        продукт.</p>
      <p>Для повернення програмного забезпечення Клієнт зобов&apos;язаний звернутися до служби підтримки через
        електронну пошту <a href="mailto:support@vetlyx.com">support@vetlyx.com</a>, вказавши причину повернення.</p>
      <p>6.9. Відшкодування</p>
      <ul>
        <li>У разі підтвердження повернення, відшкодування буде оброблено протягом 30 (тридцяти) робочих днів.</li>
        <li>Відшкодування буде здійснено на платіжний засіб, який використовувався під час покупки.</li>
        <li>Витрати на обробку платежів можуть бути вирахувані з суми відшкодування, якщо це передбачено умовами
          оплати.
        </li>
      </ul>
      <p>6.10. Виключення</p>
      <ul>
        <li>Повернення не приймаються для ліцензій на програмне забезпечення, які використовувалися, якщо не було
          виявлено критичних помилок.
        </li>
        <li>Програмне забезпечення, що було модифіковано або налаштовано безпосередньо Клієнтом, також не підлягає
          поверненню.
        </li>
      </ul>
      <p>6.11. Зв&apos;язок зі службою підтримки<br />Усі запити на повернення або відшкодування слід надсилати на
        електронну адресу служби підтримки: <a href="mailto:support@vetlyx.com">support@vetlyx.com</a>. Клієнти можуть очікувати на відповідь протягом 2 (двох)
        робочих днів.</p>
      <p>&nbsp;</p>
      <p><strong>7. Припинення обслуговування</strong></p>
      <p>7.1. Ми залишаємо за собою право призупинити або припинити ваш доступ до Vetlyx, якщо ви:</p>
      <ul>
        <li>Порушуєте ці Умови.</li>
        <li>Ділитеся особистою або медичною інформацією без згоди.</li>
        <li>Ділитеся доступом до свого облікового запису з третіми особами або неавторизованими особами.</li>
      </ul>
      <p>7.2. Після припинення обслуговування деякі ваші дані можуть бути видалені з платформи, хоча записи про тварин
        можуть залишатися доступними.</p>
      <p>&nbsp;</p>
      <p><strong>8. Послуги третіх сторін</strong></p>
      <p>8.1. Vetlyx інтегрується з сервісами третіх сторін, такими як платіжні процесори та системи звітності для
        демографічної інформації про пацієнтів. Vetlyx не несе відповідальності за політику конфіденційності або
        практики захисту даних цих сервісів, і ми рекомендуємо вам переглянути їхні умови та політики
        конфіденційності.</p>
      <p>&nbsp;</p>
      <p><strong>9. Обмеження відповідальності</strong></p>
      <p>9.1. Ми не гарантуємо точності або надійності будь-якої інформації, що надається через платформу іншими її
        учасниками. В межах, дозволених законом, Vetlyx не несе відповідальності за будь-які збитки, пов&rsquo;язані з
        використанням або неможливістю використання Сайту.</p>
      <p>&nbsp;</p>
      <p><strong>10. Зміни до угоди</strong></p>
      <p>10.1. Ми можемо змінювати ці Умови в будь-який час. Якщо зміни є значними, ми повідомимо користувачів
        електронною поштою або через платформу. Продовження використання Vetlyx після таких змін означатиме вашу згоду з
        новими Умовами.</p>
      <p>&nbsp;</p>
      <p><strong>11. Відправлення повідомлень користувачам</strong></p>
      <p>11.1. Сайт може відправляти Користувачеві різноманітні повідомлення електронною поштою, яка вказана
        Користувачем при реєстрації профілю. Повідомлення, які відправляються користувачеві, можуть включати таку
        інформацію: новий контент, акції та знижки, рекламні пропозиції третіх осіб, технічну інформацію про
        використання Сайту, інформацію про нові сервіси.</p>
      <p>11.2. Створюючи профіль на Сайті, Користувач автоматично погоджується з тим, що йому можуть бути надіслані
        листи за адресою його електронної пошти, вказаною при створенні профілю.</p>
      <p>11.3. Відмовитися від отримання електронних листів можна натиснувши на відповідне посилання в будь-якому з
        отриманих електронних листів.</p>
      <p>&nbsp;</p>
      <p><strong>12. Заключні положення</strong></p>
      <p>12.1. Ці Умови регулюються та тлумачаться відповідно до законодавства України.</p>
      <p>12.2. Усі спори, що виникають у зв&apos;язку з цими Умовами, вирішуються шляхом переговорів, а у разі
        недосягнення згоди &ndash; у судовому порядку відповідно до вимог законодавства України.</p>
      <p>12.3. Клієнт не вправі передавати свої права та обов&apos;язки за цим Договором без письмового дозволу
        Товариства. Товариство може передавати свої права та обов&apos;язки за цим Договором іншій особі без згоди
        Клієнта.</p>
      <p>12.4. Товариство не несе відповідальності за будь-яке невиконання або затримку у виконанні зобов&rsquo;язань за
        цим Договором, якщо таке невиконання або затримка сталися з причин, що знаходяться поза розумним контролем.</p>
    </>;
  };

  const renderEnText = () => {
    return <>
      <p><strong>TERMS AND CONDITIONS FOR USING THE VETERINARY SOFTWARE &quot;Vetlyx&quot;</strong></p>
      <p><strong>1. Introduction</strong></p>
      <p>1.1. These terms and conditions (hereinafter &ndash; the &quot;Terms&quot;) govern your use of the Vetlyx platform and services (hereinafter &ndash; &quot;Services,&quot; &quot;Site&quot;). By creating an account or using Vetlyx, you agree to these Terms. If you do not agree, please stop using Vetlyx.</p>
      <p>1.2. Vetlyx is owned and operated by Vetlyx LLC, with a registration address at 58 Shevchenka Street, Volya-Dobrostanska, Lviv Oblast, Ukraine. In this agreement, &apos;we,&apos; &apos;us,&apos; and &apos;our&apos; refer to Vetlyx.</p>
      <p>1.3. You may use the Site and all its services, provided that you comply with these Terms and agree to the Privacy Policy, which can be found at <a href="https://www.vetlyx.com/privacy-policy">https://www.vetlyx.com/privacy-policy</a>.</p>
      <p><strong>2. Definitions</strong></p>
      <p>2.1. <strong>User</strong>: Any individual or organization that uses the Vetlyx platform, including veterinarians, pet owners, clinic staff, etc.</p>
      <p>2.2. <strong>Account</strong>: Personalized access to Vetlyx, created by registering with personal details such as name, surname, phone number, and email address.</p>
      <p>2.3. <strong>Veterinarian</strong>: A professional registered with Vetlyx for managing patient (animal) records.</p>
      <p>2.4. <strong>Pet Owner</strong>: An individual who uses Vetlyx to manage records for their pets or receives services from a Veterinarian (see above).</p>
      <p>2.5. <strong>Site/Services</strong>: The Vetlyx platform, including all features, content, and services available through it.</p>
      <p>2.6. <strong>Organization</strong>: a legal or natural person that provides veterinary services and to which the Veterinarian has access to perform their professional duties (for example, a veterinary clinic, grooming center, etc.).</p>
      <p>2.7. <strong>Client</strong>: a person who is registered on the Website and intends to or is already using the &quot;Vetlyx&quot; software for conducting veterinary practice or management functions in the field of veterinary medicine. The Client may also include representatives of organizations that use the services and functionalities of the program on behalf of a company or institution.</p>
      <p><br/></p>
      <p><strong>3. Account Registration</strong></p>
      <p>3.1. To use Vetlyx, you must create an account by providing your personal data. By registering, you agree to provide accurate and up-to-date information.</p>
      <p>3.2. You are responsible for maintaining the confidentiality of your account credentials and for all activities performed under your account. Vetlyx accounts are intended for personal use, and you are not allowed to share your account with others.</p>
      <p>3.3. If you are a veterinarian or represent a clinic, you may need to provide additional verification information, such as professional licenses or certifications.</p>
      <p><strong>4. User Responsibilities</strong></p>
      <p>4.1. By using Vetlyx, you agree to:</p>
      <ul>
          <li>Provide truthful, accurate, and up-to-date information.</li>
          <li>Use the Site only for lawful purposes and in a manner that does not infringe upon the rights of others.</li>
          <li>As a veterinarian, ensure the accuracy of patient records and maintain the appropriate level of protection.</li>
          <li>Not share your account with others or grant access to third parties.</li>
      </ul>
      <p>4.2. <strong>Processing Client Data</strong>: If a veterinarian adds client data to the system, they are required to obtain the client&rsquo;s verbal consent to process and use their personal data in the Vetlyx system. The veterinarian must clearly explain how and for what purpose the data will be used.</p>
      <p>4.3. Prohibited actions:</p>
      <ul>
          <li>Violating any applicable laws or regulations.</li>
          <li>Sharing personal or medical information about others without their consent.</li>
          <li>Sharing access to your account with third parties.</li>
      </ul>
      <p><strong>5. Content Ownership and Management</strong></p>
      <p>5.1. Vetlyx is not responsible for content created by veterinarians or other users within the platform, including medical records and patient data.</p>
      <p>5.2. The use of software tools or programs to scan and download any materials posted on the Site is prohibited. The user or their account may be suspended indefinitely or deleted without the possibility of recovery for using such tools.</p>
      <p><strong>6. Subscriptions and Payments</strong></p>
      <p>6.1. Vetlyx offers a free version with basic features:</p>
      <ul>
          <li>Organization administration</li>
          <li>Creation of client and patient profiles</li>
          <li>Registration of vaccination data and obtaining a vaccination report for a period</li>
          <li>Working with the calendar and visit planner</li>
          <li>Registration of organization services</li>
      </ul>
      <p>6.2. Vetlyx provides users the option to upgrade to the Advanced and Advanced+ plans for access to additional features:</p>
      <ul>
          <li>Advanced: Documents</li>
          <li>Advanced: Inventory and management</li>
          <li>Advanced: Financial module</li>
          <li>Advanced: Store</li>
          <li>Advanced+: Analytics, reports, and specialized tools.</li>
      </ul>
      <p>6.3. Subscription payments are made for each individual user who is granted access to the system within a specific Organization.</p>
      <p>6.4. Payments for the Advanced and Advanced+ plans are processed through integrated third-party payment services. By subscribing to these plans, you agree to the payment terms stated at the time of subscription.</p>
      <p>6.5. Vetlyx is not responsible for any falsy payments that occur as a result of the user providing incorrect payment information or using unauthorized payment methods.</p>
      <p>6.6. Vetlyx reserves the right to change the terms of the pricing plans (Tariffs), including the prices for Services. If the Client does not agree with the changes to the pricing plan (Tariffs) and does not make subsequent payments or stops (discontinues) paying for the Services for any other reasons, the Agreement is considered terminated at the initiative of the Client unilaterally after 30 (thirty) calendar days following the expiration of access to &quot;Vetlyx&quot; under the conditions of the previous pricing plan (Tariff).</p>
      <p>6.7. Prices and tariffs can be found at the link: <a href="https://vetlyx.com/pricing">https://vetlyx.com/pricing</a></p>
      <p>6.8. Returns<br/>Clients may receive a return or refund for the &quot;Vetlyx&quot; software according to these terms if the product does not match the description.<br/>Returns are possible within 14 (fourteen) days from the date of purchase only if the software has not been used, or if critical errors that prevent product usage are found.<br/>To return the software, the Client must contact customer support via email at <a href="mailto:support@vetlyx.com">support@vetlyx.com</a>, stating the reason for the return.</p>
      <p>6.9. Refunds</p>
      <ul>
          <li>If the return is confirmed, the refund will be processed within 30 (thirty) business days.</li>
          <li>Refunds will be made to the payment method used during the purchase.</li>
          <li>Processing fees may be deducted from the refund amount, if stipulated in the payment terms.</li>
      </ul>
      <p>6.10. Exclusions</p>
      <ul>
          <li>Returns are not accepted for software licenses that have been activated or used unless critical errors have been found.</li>
          <li>Software that has been modified or customized directly by the Client is also non-returnable.</li>
      </ul>
      <p>6.11. Contacting Customer Support<br/>All requests for returns or refunds should be sent to the customer support email: <a href="mailto:support@vetlyx.com">support@vetlyx.com</a>. Clients can expect a response within 2 (two) business days.</p>
      <p><strong>7. Termination of Service</strong></p>
      <p>7.1. We reserve the right to suspend or terminate your access to Vetlyx if you:</p>
      <ul>
          <li>Violate these Terms.</li>
          <li>Share personal or medical information without consent.</li>
          <li>Share access to your account with third parties or unauthorized individuals.</li>
      </ul>
      <p>7.2. Upon termination, some of your data may be deleted from the platform, although records about animals may still remain accessible.</p>
      <p><strong>8. Third-Party Services</strong></p>
      <p>8.1. Vetlyx integrates with third-party services, such as payment processors and reporting systems for patient demographics. Vetlyx is not responsible for the privacy policies or data protection practices of these services, and we recommend that you review their terms and privacy policies.</p>
      <p><strong>9. Limitation of Liability</strong></p>
      <p>9.1. We do not guarantee the accuracy or reliability of any information provided through the platform by other users. To the extent permitted by law, Vetlyx is not liable for any losses or damages arising from the use or inability to use the Site.</p>
      <p><strong>10. Changes to the Agreement</strong></p>
      <p>10.1. We may modify these Terms at any time. If the changes are significant, we will notify users via email or through the platform. Continued use of Vetlyx after such changes constitutes your acceptance of the new Terms.</p>
      <p><strong>11. User Notifications</strong></p>
      <p>11.1. The Site may send notifications to Users via the email address provided during profile registration. These notifications may include information such as new content, promotions and discounts, third-party offers, technical information about using the Site, and information about new services.</p>
      <p>11.2. By creating a profile on the Site, the User automatically agrees to receive emails at the email address provided during profile creation.</p>
      <p>11.3. You can opt out of receiving emails by clicking the appropriate link in any of the received emails.</p>
      <p><strong>12. Final Provisions</strong></p>
      <p>12.1. These Terms are governed by and interpreted in accordance with the laws of Ukraine.</p>
      <p>12.2. Any disputes arising from these Terms shall be resolved through negotiation, and if no agreement is reached, in court in accordance with the laws of Ukraine.</p>
      <p>12.3. The client may not transfer their rights and obligations under this Agreement without the written consent of the Company. The Company may transfer its rights and obligations under this Agreement to another person without the Client&apos;s consent.</p>
      <p>12.4. The Company is not liable for any failure or delay in performing its obligations under this Agreement if such failure or delay is due to causes beyond its reasonable control.</p>
    </>;
  };

  return (
    <div className='terms-and-conditions-page'>
      {localeCode === 'ukUA' ? renderUaText() : renderEnText()}
    </div>
  );
};
