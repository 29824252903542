export const SET_BE_ERROR = '[common] BackEndError';
export const SET_LOADING = '[common] Loading';
export const SHIFT_STATE = '[common] SHIFT_STATE';

export interface SetBackendErrorAction {
  type: typeof SET_BE_ERROR
  payload: boolean
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface SetShiftStateAction {
  type: typeof SHIFT_STATE
  payload: boolean
}

export type CommonActions =
  SetShiftStateAction |
  SetBackendErrorAction |
  SetLoadingAction;
