import './EventsCalendar.scss';

import { Modal, Row, Typography } from 'antd';
import React from 'react';
import { EventsIndexResponse } from 'services/interfaces/clinics/events/EventsIndexResponse';
import { getDateMonthTime } from 'utils/get-time';

const { Title } = Typography;

type Props = {
  events: EventsIndexResponse[];
  onCancel: () => void;
};

export const AllDayEventsModal = ({ events, onCancel }: Props): JSX.Element => {
  return (
    <Modal
      open
      onCancel={onCancel}
      footer={null}
      width={'auto'}
      centered
      styles={{
        content: { minWidth: 350 },
        mask: { backgroundColor: 'transparent' }
      }}
    >
      <>
        {events.map((event, i) => (
          <React.Fragment key={event.id}>
            <Row style={{ alignItems: 'baseline' }}>
              <Title level={5} style={{ marginRight: '10px' }}>
                {i + 1}. {event?.title}
              </Title>
              <span className='icofont-ui-calendar' style={{ marginRight: '6px' }}></span>
              <span>{getDateMonthTime(event)}</span>
            </Row>
          </React.Fragment>
        ))}
      </>
    </Modal>
  );
};
