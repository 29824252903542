import '../Finances.scss';

import { Button, Col, Form, Pagination, Row, Spin, Table, TableColumnProps, Tooltip } from 'antd';
import StickyButton from 'components/StickyButton';
import VLXSearch from 'components/VLXSearch';
import { financialAccountsTypesOptions, financialAcountsOptions } from 'constants/dictionary/default/selectOptions';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import AddFinancialAccount from 'layout/modals/finances/addFinancialAccount/AddFinancialAccount';
import { TransferAccounts } from 'layout/modals/finances/transferAccounts/TransferAccounts';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getAccounts,
  showModal as toogleShowModal,
  toogleShowTransferModal,
} from 'redux/finances/financialAccounts/actions';
import { ShowAccountResponse } from 'services/clinic/accounts/models/ShowAccountResponse';
import valueFromList from 'utils/value-from-list';

import { useSearch } from '../../../hooks/useSearch.ts';

const FinancialAccountsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const [addAccountForm] = Form.useForm();
  const locale = useLocale('private.finances.financial-accounts');
  const currencies = useLocale('private.currencies').labels;

  const [selectedAccountId, setSelectedAccountId] = useState(null);

  const { data, metadata, showModal } = useSelector(
    ({ financialAccounts }: IAppState) => financialAccounts
  );
  const loading = useSelector(({ financialAccounts }: IAppState) => financialAccounts.loading);
  const openedShift = useSelector(({ common }: IAppState) => common.shiftOpened);

  const { handleChange, onPageChange, filterResults, getResults } = useSearch({
    action: getAccounts,
    clinicId,
    defaultSortKey: 'name',
    defaultSortOrder: 'asc'
  });

  const redirectToAccountDetails = (record: ShowAccountResponse): { onClick: () => void } => ({
    onClick: (): void => {
      navigate(`/clinic/${clinicId}/finances/financial-accounts/${record.id}`);
    }
  });

  const localizedFinancialAcountsOptions = useLocalizedList(financialAcountsOptions);
  const localizedFinancialAcountsTypesOptions = useLocalizedList(financialAccountsTypesOptions);

  const getStatus = (e: ShowAccountResponse): string => {
    const res = localizedFinancialAcountsOptions.find((el) => el.value === e);
    return res ? res.label : '';
  };

  const columns = useMemo(
    (): TableColumnProps<ShowAccountResponse>[] => [
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        render: (name) => name ?? '-',
        onCell: redirectToAccountDetails
      },
      {
        key: 'account_type',
        dataIndex: 'account_type',
        title: locale.labels.accountType,
        sorter: (a, b) => (a.account_type > b.account_type ? 1 : -1),
        render: (account_type) =>
          valueFromList(localizedFinancialAcountsTypesOptions, account_type),
        onCell: redirectToAccountDetails
      },
      {
        key: 'account_number',
        dataIndex: 'account_number',
        title: locale.labels.number,
        sorter: (a, b) => (a.account_number > b.account_number ? 1 : -1),
        render: (account_number) => account_number ?? '-',
        onCell: redirectToAccountDetails
      },
      {
        key: 'current_balance',
        dataIndex: 'current_balance',
        title: locale.labels.currentBalance,
        sorter: (a, b) => (a.current_balance > b.current_balance ? 1 : -1),
        render: (current_balance, e) => `${money(current_balance)} ${currencies.uah}`,
        onCell: redirectToAccountDetails
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: locale.labels.status,
        sorter: (a, b) => (a.status > b.status ? 1 : -1),
        render: getStatus,
        onCell: redirectToAccountDetails
      },
      {
        key: 'id',
        title: locale.labels.actions,
        render: (e) => (
          <Tooltip title={!openedShift ? locale.labels.paymentBlockedClosedShift : null}>
            <Col style={{ width: 'min-content' }}>
              <Button
                className={'icofont icofont-loop open-modal-button transfer-button-table-view'}
                type={'primary'}
                disabled={!openedShift}
                onClick={() => { setSelectedAccountId(e.id); dispatch(toogleShowTransferModal(true)); }}
              >
              </Button>
            </Col>
          </Tooltip>
        )
      }
    ],
    [data, openedShift]
  );

  return (
    <>
      <VLXSearch filterResults={filterResults} savedFiltersName={'accountsListFilters'} />
      <Spin spinning={loading} className='financial-accounts-list-page-spin-container'>
        <Table
          pagination={false}
          className='accent-header financial-accounts-list-table'
          rowKey='id'
          dataSource={data}
          columns={columns}
          onChange={handleChange}
        />
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center'
          }}
        >
          <Pagination
            defaultCurrent={1}
            pageSize={20}
            current={metadata.page}
            total={metadata.total}
            onChange={onPageChange}
            showSizeChanger={false}
            showLessItems
          />
          <StickyButton
            offsetBottom={10}
            onClick={() => dispatch(toogleShowModal(true))}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={locale.buttons.addAccount}
          />
          {showModal && <AddFinancialAccount form={addAccountForm} hideDefaultCreateBtn />}
        </Row>
      </Spin>
      <TransferAccounts callbackFunc={getResults} currentAccountId={selectedAccountId} />
    </>
  );
};

export default FinancialAccountsList;
