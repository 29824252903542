import './EventsCalendar.scss';

import { getFirstAndLastName, getInitials } from './utils/name-utils';

export const CustomViewHeader = ({ children, resource }): JSX.Element => {
  const name = resource.full_name;
  const hasTitle = !!resource?.title;

  return (
    <div className='custom-event-header-wrapper'>
      <div className='avatar-container'>
        {resource.avatar ? (
          <img src={resource.avatar} alt={name} className='avatar' />
        ) : (
          <div className='avatar-placeholder' style={{ backgroundColor: resource.color }}>
            {getInitials(name)}
          </div>
        )}
      </div>
      <div className='info-container'>
        <div className='label'>{getFirstAndLastName(name)}</div>
        {hasTitle && <div className='title'>{resource.title}</div>}
      </div>
      {children}
    </div>
  );
};
