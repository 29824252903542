import moment from 'moment';

export const CustomEventContainer = ({ event }): JSX.Element => {
  if (event.allDay) return <div>{event.title}</div>;

  const is15minutesEvent = moment.duration(event.end - event.start).asMinutes() === 15;

  if (is15minutesEvent) return <strong>{event.title}</strong>;

  return (
    <div style={{ marginTop: '2px' }}>
      <strong>{event.title}</strong>
      <div style={{ padding: '6px 0 13px' }}>
        <i className='icofont-clock-time' style={{ marginRight: '4px' }} />
        {event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} -{' '}
        {event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
      </div>
      <div>{event?.location?.details}</div>
    </div>
  );
};
