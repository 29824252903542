import { Button, Menu, MenuProps, Row } from 'antd';
import VLXSearch from 'components/VLXSearch';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { useSearch } from 'hooks/useSearch';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getPatients, getVaccinations, toggleGenerateVaccinationReportModal } from 'redux/patients/actions';

import { PatientsTab } from './PatientsTab';
import { VaccinationsTab } from './VaccinationsTab';

export const PatientsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const locale = useLocale('private.patients.patients-list');
  const clinicId = useClinicId();
  const localePage = useLocale('private.patients.patient-page');
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTabSearchParam = searchParams.get('tab');
    const [currentTab, setCurrentTab] = useState(activeTabSearchParam || 'patients');

  useEffect(() => {
    if (activeTabSearchParam && activeTabSearchParam !== currentTab) {
      setCurrentTab(activeTabSearchParam);
    }
  }, [activeTabSearchParam]);

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        label: locale.tabs.patients,
        key: 'patients'
      },
      {
        label: locale.tabs.vaccinations,
        key: 'vaccinations'
      }
    ],
    [currentTab, activeTabSearchParam]
  );

  const changeTab: MenuProps['onClick'] = (e): void => {
    if (e.key !== currentTab) {
      setSearchParams({});
      setCurrentTab(e.key);
    }
  };

    const {
    handleChange,
    onPageChange,
    getResults,
    filterResults
  } = useSearch({
    action: currentTab === 'patients' ? getPatients : getVaccinations,
    clinicId,
    defaultSortKey: 'created_at',
    defaultSortOrder: 'desc',
    customSearchParam: {tab: currentTab}
  });

  return (
    <>
      <Menu
        onClick={changeTab}
        selectedKeys={[currentTab]}
        mode='horizontal'
        items={items}
        style={{ marginBottom: 30, marginTop: 10 }}
      />
      <Row justify={'space-between'} style={{ gap: '0 20px' }}>
        <VLXSearch filterResults={filterResults} savedFiltersName={currentTab === 'patients' ? '' : 'vaccinationsListFilters'} />
        {currentTab === 'vaccinations' && <Button
          id={'create-vaccination-report'}
          className='composit-search-select-option-search-button'
          type='primary'
          style={{ marginBottom: 20 }}
          onClick={() => dispatch(toggleGenerateVaccinationReportModal(true))}
        >
          {localePage.buttons.createReport}
        </Button>}
      </Row>
      <div key='patients-list-patient-tab'>{currentTab === 'patients' && <PatientsTab getResults={getResults} onPageChange={onPageChange} handleChange={handleChange}/>}</div>
      <div key='patients-list-vaccination-tab'>{currentTab === 'vaccinations' && <VaccinationsTab getResults={getResults} onPageChange={onPageChange} handleChange={handleChange} />}</div>
    </>
  );
};
