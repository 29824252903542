import { IAppState } from 'interfaces/app-state';
import React from 'react';
import { useSelector } from 'react-redux';

export const Privacy = (): JSX.Element => {
  const localeCode = useSelector(({ localeCode }: IAppState) => localeCode);

  const renderUaText = () => {
    return <>
    <p><strong>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ &quot;Vetlyx&quot;</strong></p>
    <p>Ласкаво просимо до онлайн-платформи &quot;Vetlyx&quot;. Ми цінуємо вашу довіру та прагнемо захистити вашу особисту інформацію. Ця Політика конфіденційності пояснює, як Товариство з обмеженою відповідальністю &quot;Vetlyx&quot; (далі &mdash; &quot;ми&quot;, &quot;нас&quot;, &quot;наш&quot;) збирає, використовує, розкриває та захищає ваші персональні дані при користуванні нашим веб-сайтом та мобільним додатком (разом &mdash; &quot;онлайн-сервіс&quot;).</p>
    <p><strong>1. ВАЖЛИВА ІНФОРМАЦІЯ ТА ХТО МИ Є</strong></p>
    <p>Ця Політика конфіденційності призначена для того, щоб інформувати вас про те, як ми обробляємо ваші персональні дані, коли ви користуєтеся нашим онлайн-сервісом. Важливо, щоб ви уважно прочитали цю Політику та були впевнені, що ви розумієте наші практики щодо ваших персональних даних.</p>
    <p><strong>Контактна інформація:</strong></p>
    <ul>
        <li>Електронна пошта: <a href="mailto:support@vetlyx.com">support@vetlyx.com</a></li>
    </ul>
    <p><strong>2. ЯКІ ДАНІ МИ ЗБИРАЄМО</strong></p>
    <p>Ми можемо збирати, використовувати та зберігати різні види персональних даних про вас:</p>
    <ul>
        <li>Особиста інформація:<ul>
                <li>Ідентифікаційні дані: ім&apos;я, прізвище, по-батькові, дата народження</li>
                <li>Контактні дані: номер телефону, електронна адреса</li>
                <li>Інші дані, які ви вказуєте під час користування сервісом</li>
            </ul>
        </li>
        <li>Медичні записи:<ul>
                <li>Інформація про тварин, включаючи ветеринарні звіти, історію лікування, завантажені документи та зображення.</li>
            </ul>
        </li>
        <li>Фінансова інформація:<ul>
                <li>Платіжні дані, зібрані під час підписки на плани PRO або PRO+.</li>
            </ul>
        </li>
        <li>Технічні дані:<ul>
                <li>IP-адреса, тип та версія браузера, час доступу, налаштування часової зони, операційна система, тип пристрою.</li>
            </ul>
        </li>
        <li>Дані профілю:<ul>
                <li>Ваш логін та пароль, покупки або замовлення, зроблені вами, ваші інтереси, відгуки.</li>
            </ul>
        </li>
        <li>Дані використання:<ul>
                <li>Інформація про те, як ви використовуєте наш онлайн-сервіс.</li>
            </ul>
        </li>
        <li>Маркетингові та комунікаційні дані:<ul>
                <li>Ваші вподобання щодо отримання від нас маркетингових матеріалів.</li>
            </ul>
        </li>
    </ul>
    <p><strong>3. ЯК МИ ЗБИРАЄМО ВАШІ ДАНІ</strong></p>
    <p>Ми збираємо ваші персональні дані різними способами:</p>
    <ul>
        <li>Пряма взаємодія:<ul>
                <li>Ви надаєте нам свої дані, заповнюючи форми на нашому онлайн-сервісі або спілкуючись з нами електронною поштою чи телефоном.</li>
            </ul>
        </li>
        <li>Автоматизовані технології:<ul>
                <li>Під час вашої взаємодії з нашим сервісом ми автоматично збираємо технічні дані.</li>
            </ul>
        </li>
        <li>Треті особи:<ul>
                <li>Ми можемо отримувати дані від третіх осіб, таких як постачальники технічних послуг, платіжні системи.</li>
            </ul>
        </li>
    </ul>
    <p><strong>4. МЕТА ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ</strong></p>
    <p>Ми використовуємо ваші персональні дані для наступних цілей:</p>
    <ul>
        <li>Надання послуг:<ul>
                <li>Управління вашими обліковими записами.</li>
                <li>Ведення медичних записів тварин.</li>
                <li>Обробка платежів та управління підписками.</li>
            </ul>
        </li>
        <li>Покращення сервісу:<ul>
                <li>Аналіз та покращення роботи нашого онлайн-сервісу.</li>
                <li>Персоналізація вашого досвіду користування.</li>
            </ul>
        </li>
        <li>Комунікація:<ul>
                <li>Інформування про оновлення, зміни в політиці, акції та спеціальні пропозиції.</li>
                <li>Надання підтримки та відповідей на ваші запити.</li>
            </ul>
        </li>
        <li>Забезпечення безпеки:<ul>
                <li>Захист нашого онлайн-сервісу від шахрайства та незаконної діяльності.</li>
            </ul>
        </li>
        <li>Юридичні зобов&apos;язання:<ul>
                <li>Виконання вимог законодавства та нормативних актів.</li>
            </ul>
        </li>
    </ul>
    <p><strong>5. ПРАВОВІ ПІДСТАВИ ОБРОБКИ</strong></p>
    <p>Ми обробляємо ваші персональні дані на основі:</p>
    <ul>
        <li>Вашої згоди:<ul>
                <li>Коли ви надаєте нам дозвіл на обробку ваших даних для конкретних цілей.</li>
            </ul>
        </li>
        <li>Виконання договору:<ul>
                <li>Коли обробка необхідна для надання послуг.</li>
            </ul>
        </li>
        <li>Законних інтересів:<ul>
                <li>Для забезпечення безпеки та покращення нашого сервісу.</li>
            </ul>
        </li>
        <li>Юридичних зобов&apos;язань:<ul>
                <li>Коли ми повинні виконати юридичні або регуляторні вимоги.</li>
            </ul>
        </li>
    </ul>
    <p><strong>6. ОБМІН ТА РОЗКРИТТЯ ДАНИХ</strong></p>
    <p>Ми не передаємо ваші персональні або медичні дані третім особам без вашої явної згоди, за винятком наступних випадків:</p>
    <ul>
        <li>Постачальники послуг:<ul>
                <li>Ми можемо передавати ваші дані постачальникам, які надають нам послуги (наприклад, платіжні процесори). Вони обробляють ваші дані лише за нашими вказівками та зобов&apos;язані дотримуватися конфіденційності.</li>
            </ul>
        </li>
        <li>Агреговані дані:<ul>
                <li>Ми можемо використовувати анонімні, агреговані дані для статистичних та демографічних цілей (наприклад, загальна кількість тварин у регіоні).</li>
            </ul>
        </li>
        <li>Юридичні вимоги:<ul>
                <li>Якщо це вимагається законом, ми можемо розкрити ваші дані відповідним органам влади.</li>
            </ul>
        </li>
    </ul>
    <p><strong>7. МІЖНАРОДНІ ПЕРЕДАЧІ ДАНИХ</strong></p>
    <p>Ваші дані зберігаються на захищених серверах у європейському регіоні. У випадку передачі даних за межі України, ми забезпечимо належний рівень захисту відповідно до чинного законодавства.</p>
    <p><strong>8. БЕЗПЕКА ДАНИХ</strong></p>
    <p>Ми впроваджуємо відповідні технічні та організаційні заходи для захисту ваших персональних даних від несанкціонованого доступу, втрати або розголошення. Це включає:</p>
    <ul>
        <li>Шифрування даних:<ul>
                <li>Використання сучасних методів шифрування для захисту інформації.</li>
            </ul>
        </li>
        <li>Контроль доступу:<ul>
                <li>Обмеження доступу до ваших даних лише тим співробітникам, які потребують їх для виконання своїх обов&apos;язків.</li>
            </ul>
        </li>
        <li>Регулярний аудит:<ul>
                <li>Періодичний перегляд наших практик безпеки для виявлення та усунення потенційних вразливостей.</li>
            </ul>
        </li>
    </ul>
    <p><strong>9. ЗБЕРІГАННЯ ДАНИХ</strong></p>
    <p>Ми зберігаємо ваші персональні дані лише протягом часу, необхідного для досягнення цілей, визначених цією Політикою, або для виконання юридичних зобов&apos;язань. Після закінчення цього періоду ми безпечно видаляємо або анонімізуємо ваші дані.</p>
    <p><strong>10. ВАШІ ПРАВА</strong></p>
    <p>Відповідно до законодавства України, ви маєте наступні права щодо ваших персональних даних:</p>
    <ul>
        <li>Право доступу:<ul>
                <li>Ви можете запитати підтвердження того, чи обробляються ваші персональні дані, та отримати доступ до них.</li>
            </ul>
        </li>
        <li>Право на виправлення:<ul>
                <li>Ви маєте право вимагати виправлення неточних або неповних даних.</li>
            </ul>
        </li>
        <li>Право на видалення:<ul>
                <li>Ви можете вимагати видалення ваших персональних даних у певних випадках.</li>
            </ul>
        </li>
        <li>Право на обмеження обробки:<ul>
                <li>Ви маєте право вимагати обмеження обробки ваших даних за певних обставин.</li>
            </ul>
        </li>
        <li>Право на перенесення даних:<ul>
                <li>Ви можете отримати копію ваших даних у структурованому, загальноприйнятому форматі.</li>
            </ul>
        </li>
        <li>Право на заперечення:<ul>
                <li>Ви маєте право заперечувати проти обробки ваших даних з певних причин.</li>
            </ul>
        </li>
        <li>Право відкликати згоду:<ul>
                <li>Якщо обробка здійснюється на основі вашої згоди, ви можете відкликати її в будь-який час.</li>
            </ul>
        </li>
    </ul>
    <p>Для реалізації ваших прав, будь ласка, зв&apos;яжіться з нами за адресою <a href="mailto:support@vetlyx.com">support@vetlyx.com</a>. Ми відповімо на ваш запит у найкоротші терміни.</p>
    <p><strong>11. ФАЙЛИ COOKIE ТА ІНШІ ТЕХНОЛОГІЇ ВІДСТЕЖЕННЯ</strong></p>
    <p>Ми використовуємо файли cookie та подібні технології для покращення роботи нашого онлайн-сервісу. Це допомагає нам:</p>
    <ul>
        <li>Запам&apos;ятовувати ваші налаштування та вподобання.</li>
        <li>Розуміти, як ви використовуєте наш сервіс, для його покращення.</li>
        <li>Забезпечувати безпеку та цілісність сервісу.</li>
    </ul>
    <p>Ви можете керувати налаштуваннями файлів cookie у вашому браузері. Однак, відключення файлів cookie може вплинути на функціональність сервісу.</p>
    <p><strong>12. КОНФІДЕНЦІЙНІСТЬ ДІТЕЙ</strong></p>
    <p>Наш онлайн-сервіс не призначений для використання дітьми віком до 13 років. Ми свідомо не збираємо персональні дані дітей без згоди батьків або законних представників. Якщо нам стане відомо, що ми зібрали дані дитини без належної згоди, ми вживемо заходів для видалення такої інформації.</p>
    <p><strong>13. ПОСИЛАННЯ НА СТОРОННІ РЕСУРСИ</strong></p>
    <p>Наш сервіс може містити посилання на сторонні веб-сайти або додатки. Ми не несемо відповідальності за практики конфіденційності цих сторонніх ресурсів. Ми рекомендуємо ознайомитися з їхніми політиками конфіденційності перед тим, як надавати їм будь-які персональні дані.</p>
    <p><strong>14. ЗМІНИ ДО ЦІЄЇ ПОЛІТИКИ</strong></p>
    <p>Ми можемо час від часу оновлювати цю Політику конфіденційності. У разі внесення значних змін, ми повідомимо вас через електронну пошту або шляхом повідомлення на нашому онлайн-сервісі. Рекомендуємо регулярно переглядати цю Політику для отримання актуальної інформації про наші практики конфіденційності.</p>
    <p><strong>15. ЯК ЗВ&apos;ЯЗАТИСЯ З НАМИ</strong></p>
    <p>Якщо у вас є запитання, коментарі або запити щодо цієї Політики конфіденційності, будь ласка, зв&apos;яжіться з нами:</p>
    <ul>
        <li>Електронна пошта: <a href="mailto:support@vetlyx.com">support@vetlyx.com</a></li>
    </ul>
    <p><strong>16. ВАШІ ОБОВ&apos;ЯЗКИ</strong></p>
    <p>Важливо, щоб інформація, яку ми маємо про вас, була точною та актуальною. Будь ласка, інформуйте нас про зміни у ваших персональних даних протягом вашого використання нашого онлайн-сервісу.</p>
    <p><strong>17. ПРАВОВА ОСНОВА ДЛЯ ОБРОБКИ</strong></p>
    <p>Ми будемо обробляти ваші персональні дані лише тоді, коли це дозволено законом, зокрема:</p>
    <ul>
        <li>Згода: коли ви надали згоду на обробку ваших даних.</li>
        <li>Виконання договору: коли обробка необхідна для виконання договору з вами.</li>
        <li>Юридичні зобов&apos;язання: коли ми повинні виконати юридичні вимоги.</li>
        <li>Законні інтереси: коли обробка необхідна для наших законних інтересів або інтересів третьої сторони, і ваші права не переважають ці інтереси.</li>
    </ul>
    <p><strong>18. БЕЗПЕКА ПЕРЕДАЧІ ДАНИХ</strong></p>
    <p>Ми застосовуємо сучасні методи шифрування для захисту передачі даних між вашим пристроєм та нашими серверами. Однак, жоден метод передачі через інтернет або метод електронного зберігання не є абсолютно безпечним. Хоча ми прагнемо використовувати комерційно прийнятні засоби для захисту ваших персональних даних, ми не можемо гарантувати їх абсолютну безпеку.</p>
    <p><strong>19. МАРКЕТИНГОВІ КОМУНІКАЦІЇ</strong></p>
    <p>Ми можемо надсилати вам маркетингові повідомлення про наші послуги та акції. Ви можете в будь-який час відмовитися від отримання таких повідомлень, слідуючи інструкціям у повідомленні або зв&apos;язавшись з нами.</p>
    <p><strong>20. СКАРГИ ТА ВИРІШЕННЯ СПОРІВ</strong></p>
    <p>Якщо ви вважаєте, що ми порушили ваші права щодо конфіденційності, ви маєте право подати скаргу до відповідного органу з питань захисту даних в Україні. Ми цінуємо можливість вирішити ваші питання безпосередньо, тому рекомендуємо спочатку зв&apos;язатися з нами.</p>
    <p><strong>21. ПРИЙНЯТТЯ УМОВ</strong></p>
    <p>Використовуючи наш онлайн-сервіс, ви підтверджуєте, що ознайомилися з цією Політикою конфіденційності та погоджуєтеся з її умовами.</p>
    <p>Дякуємо за довіру до &quot;Vetlyx&quot;. Ми зобов&apos;язуємося захищати вашу конфіденційність та забезпечувати безпеку ваших персональних даних.</p>
  </>;
  };

  const renderEnText = () => {
    return <>
      <p><strong>Vetlyx Privacy Policy</strong></p>
      <p>Welcome to the Vetlyx online platform. We value your trust and are committed to protecting your personal information. This Privacy Policy explains how Vetlyx LLC (hereinafter referred to as &quot;we,&quot; &quot;us,&quot; &quot;our&quot;) collects, uses, discloses, and protects your personal data when you use our website and mobile application (collectively, &quot;online service&quot;).</p>
      <p><strong>1. IMPORTANT INFORMATION AND WHO WE ARE</strong></p>
      <p>This Privacy Policy is intended to inform you about how we process your personal data when you use our online service. It is important that you carefully read this Policy to ensure you understand our practices regarding your personal data.</p>
      <p><strong>Contact Information:</strong></p>
      <ul>
          <li>Email: <a href="mailto:support@vetlyx.com">support@vetlyx.com</a></li>
      </ul>
      <p><strong>2. DATA WE COLLECT</strong></p>
      <p>We may collect, use, and store various types of personal data about you:</p>
      <ul>
          <li><strong>Personal Information</strong>:<ul>
                  <li><strong>Identification Data</strong>: name, surname, middle name, date of birth.</li>
                  <li><strong>Contact Information</strong>: phone number, email address.</li>
                  <li><strong>Other Information</strong>: any other data you provide while using the service.</li>
              </ul>
          </li>
          <li><strong>Medical Records</strong>:<ul>
                  <li>Information about animals, including veterinary reports, treatment history, uploaded documents, and images.</li>
              </ul>
          </li>
          <li><strong>Financial Information</strong>:<ul>
                  <li>Payment details collected during subscriptions to PRO or PRO+ plans.</li>
              </ul>
          </li>
          <li><strong>Technical Data</strong>:<ul>
                  <li>IP address, browser type and version, access time, time zone settings, operating system, device type.</li>
              </ul>
          </li>
          <li><strong>Profile Data</strong>:<ul>
                  <li>Your login and password, purchases or orders made by you, your interests, reviews.</li>
              </ul>
          </li>
          <li><strong>Usage Data</strong>:<ul>
                  <li>Information on how you use our online service.</li>
              </ul>
          </li>
          <li><strong>Marketing and Communication Data</strong>:<ul>
                  <li>Your preferences regarding receiving marketing materials from us.</li>
              </ul>
          </li>
      </ul>
      <p><strong>3. HOW WE COLLECT YOUR DATA</strong></p>
      <p>We collect your personal data in various ways:</p>
      <ul>
          <li><strong>Direct Interaction</strong>:<ul>
                  <li>You provide your data by filling out forms on our online service or communicating with us via email or phone.</li>
              </ul>
          </li>
          <li><strong>Automated Technologies</strong>:<ul>
                  <li>During your interaction with our service, we automatically collect technical data.</li>
              </ul>
          </li>
          <li><strong>Third Parties</strong>:<ul>
                  <li>We may receive data from third parties, such as service providers, technical services, or payment systems.</li>
              </ul>
          </li>
      </ul>
      <p><strong>4. PURPOSE OF DATA PROCESSING</strong></p>
      <p>We use your personal data for the following purposes:</p>
      <ul>
          <li><strong>Service Provision</strong>:<ul>
                  <li>Managing your accounts.</li>
                  <li>Keeping medical records for animals.</li>
                  <li>Processing payments and managing subscriptions.</li>
              </ul>
          </li>
          <li><strong>Service Improvement</strong>:<ul>
                  <li>Analyzing and improving the operation of our online service.</li>
                  <li>Personalizing your user experience.</li>
              </ul>
          </li>
          <li><strong>Communication</strong>:<ul>
                  <li>Informing you about updates, policy changes, promotions, and special offers.</li>
                  <li>Providing support and responding to your inquiries.</li>
              </ul>
          </li>
          <li><strong>Security</strong>:<ul>
                  <li>Protecting our online service from fraud and unlawful activities.</li>
              </ul>
          </li>
          <li><strong>Legal Obligations</strong>:<ul>
                  <li>Complying with legal and regulatory requirements.</li>
              </ul>
          </li>
      </ul>
      <p><strong>5. LEGAL BASIS FOR PROCESSING</strong></p>
      <p>We process your personal data based on:</p>
      <ul>
          <li><strong>Your Consent</strong>:<ul>
                  <li>When you give us permission to process your data for specific purposes.</li>
              </ul>
          </li>
          <li><strong>Contract Fulfillment</strong>:<ul>
                  <li>When processing is necessary to provide services to you.</li>
              </ul>
          </li>
          <li><strong>Legitimate Interests</strong>:<ul>
                  <li>To ensure the security and improvement of our service.</li>
              </ul>
          </li>
          <li><strong>Legal Obligations</strong>:<ul>
                  <li>When we are required to comply with legal or regulatory requirements.</li>
              </ul>
          </li>
      </ul>
      <p><strong>6. DATA SHARING AND DISCLOSURE</strong></p>
      <p>We do not share your personal or medical data with third parties without your explicit consent, except in the following cases:</p>
      <ul>
          <li><strong>Service Providers</strong>:<ul>
                  <li>We may share your data with service providers (e.g., payment processors) who process your data only according to our instructions and are obligated to maintain confidentiality.</li>
              </ul>
          </li>
          <li><strong>Aggregated Data</strong>:<ul>
                  <li>We may use anonymous, aggregated data for statistical and demographic purposes (e.g., total number of animals in a region).</li>
              </ul>
          </li>
          <li><strong>Legal Requirements</strong>:<ul>
                  <li>If required by law, we may disclose your data to relevant authorities.</li>
              </ul>
          </li>
      </ul>
      <p><strong>7. INTERNATIONAL DATA TRANSFERS</strong></p>
      <p>Your data is stored on secure servers located in the European region. In the case of data transfers outside Ukraine, we ensure an appropriate level of protection in accordance with applicable laws.</p>
      <p><strong>8. DATA SECURITY</strong></p>
      <p>We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, loss, or disclosure, including:</p>
      <ul>
          <li><strong>Data Encryption</strong>:<ul>
                  <li>Using modern encryption methods to secure information.</li>
              </ul>
          </li>
          <li><strong>Access Control</strong>:<ul>
                  <li>Limiting access to your data only to employees who need it to perform their duties.</li>
              </ul>
          </li>
          <li><strong>Regular Audits</strong>:<ul>
                  <li>Periodically reviewing our security practices to identify and address potential vulnerabilities.</li>
              </ul>
          </li>
      </ul>
      <p><strong>9. DATA RETENTION</strong></p>
      <p>We retain your personal data only for as long as necessary to fulfill the purposes outlined in this Policy or to comply with legal obligations. After this period, we securely delete or anonymize your data.</p>
      <p><strong>10. YOUR RIGHTS</strong></p>
      <p>According to Ukrainian law, you have the following rights regarding your personal data:</p>
      <ul>
          <li><strong>Right to Access</strong>:<ul>
                  <li>You can request confirmation of whether your personal data is being processed and obtain access to it.</li>
              </ul>
          </li>
          <li><strong>Right to Rectification</strong>:<ul>
                  <li>You can request the correction of inaccurate or incomplete data.</li>
              </ul>
          </li>
          <li><strong>Right to Erasure</strong>:<ul>
                  <li>You can request the deletion of your personal data in certain circumstances.</li>
              </ul>
          </li>
          <li><strong>Right to Restriction of Processing</strong>:<ul>
                  <li>You can request the restriction of processing your data under certain conditions.</li>
              </ul>
          </li>
          <li><strong>Right to Data Portability</strong>:<ul>
                  <li>You can receive a copy of your data in a structured, commonly used format.</li>
              </ul>
          </li>
          <li><strong>Right to Object</strong>:<ul>
                  <li>You can object to the processing of your data for specific reasons.</li>
              </ul>
          </li>
          <li><strong>Right to Withdraw Consent</strong>:<ul>
                  <li>If processing is based on your consent, you may withdraw it at any time.</li>
              </ul>
          </li>
      </ul>
      <p>To exercise your rights, please contact us at <a href="mailto:support@vetlyx.com">support@vetlyx.com</a>. We will respond to your request as soon as possible.</p>
      <p><strong>11. COOKIES AND OTHER TRACKING TECHNOLOGIES</strong></p>
      <p>We use cookies and similar technologies to enhance the functionality of our online service. This helps us:</p>
      <ul>
          <li>Remember your preferences and settings.</li>
          <li>Understand how you use our service to improve it.</li>
          <li>Ensure the security and integrity of the service.</li>
      </ul>
      <p>You can manage cookie settings in your browser. However, disabling cookies may affect the functionality of the service.</p>
      <p><strong>12. CHILDREN&apos;S PRIVACY</strong></p>
      <p>Our online service is not intended for children under the age of 13. We do not knowingly collect personal data from children without parental or guardian consent. If we become aware that we have collected personal data from a child without proper consent, we will take steps to delete such information.</p>
      <p><strong>13. LINKS TO THIRD-PARTY RESOURCES</strong></p>
      <p>Our service may contain links to third-party websites or applications. We are not responsible for the privacy practices of these third parties. We recommend reviewing their privacy policies before providing them with any personal data.</p>
      <p><strong>14. CHANGES TO THIS POLICY</strong></p>
      <p>We may update this Privacy Policy from time to time. If significant changes are made, we will notify you via email or through our online service. We recommend regularly reviewing this Policy to stay informed about our privacy practices.</p>
      <p><strong>15. HOW TO CONTACT US</strong></p>
      <p>If you have any questions, comments, or requests regarding this Privacy Policy, please contact us at:</p>
      <ul>
          <li>Email: <a href="mailto:support@vetlyx.com">support@vetlyx.com</a></li>
      </ul>
      <p><strong>16. YOUR RESPONSIBILITIES</strong></p>
      <p>It is important that the information we hold about you is accurate and up-to-date. Please inform us of any changes to your personal data during your use of our online service.</p>
      <p><strong>17. LEGAL BASIS FOR PROCESSING</strong></p>
      <p>We will process your personal data only when permitted by law, including:</p>
      <ul>
          <li><strong>Consent</strong>: when you have provided consent for data processing.</li>
          <li><strong>Contract Fulfillment</strong>: when processing is necessary for the performance of a contract with you.</li>
          <li><strong>Legal Obligations</strong>: when we must comply with legal requirements.</li>
          <li><strong>Legitimate Interests</strong>: when processing is necessary for our legitimate interests or the legitimate interests of a third party, provided that your rights do not override those interests.</li>
      </ul>
      <p><strong>18. DATA TRANSMISSION SECURITY</strong></p>
      <p>We apply modern encryption methods to secure data transmission between your device and our servers. However, no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.</p>
      <p><strong>19. MARKETING COMMUNICATIONS</strong></p>
      <p>We may send you marketing messages about our services and promotions. You can opt out of receiving such messages at any time by following the instructions in the message or contacting us.</p>
      <p><strong>20. COMPLAINTS AND DISPUTE RESOLUTION</strong></p>
      <p>If you believe we have violated your privacy rights, you have the right to file a complaint with the relevant data protection authority in Ukraine. We value the opportunity to address your concerns directly and encourage you to contact us first.</p>
      <p><strong>21. ACCEPTANCE OF TERMS</strong></p>
      <p>By using our online service, you confirm that you have read and agree to this Privacy Policy and its terms.</p>
      <p>Thank you for trusting Vetlyx. We are committed to protecting your privacy and ensuring the security of your personal data.</p>
    </>;
  };

  return (
    <div className='terms-and-conditions-page'>
      {localeCode === 'ukUA' ? renderUaText() : renderEnText()}
    </div>
  );
};
