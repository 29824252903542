import { Button, Col, List, Row, Tooltip } from 'antd';
import { useLocale } from 'hooks/useLocale';
import React, { useCallback, useMemo } from 'react';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem';

import { useGetResidual } from './utils/useGetResidual';

interface Props {
  item: IStockItem;
}

export const StockListPriceItem = ({item}: Props) => {
  const currencies = useLocale('private.currencies').labels;
  const locale = useLocale('private.inventory.stock');

  const { getResidual, unitOfMeasurement, packagingFormat, noPackageFormat } =
    useGetResidual(item);

  const getStockPrices = useMemo((): string[] =>
    (item?.prices || [])
      .filter((el) => !el.deleted && el.residual)
      .sort((el) => (el.type === 'default' ? -1 : 1))
      .map((el) => {
        const isDefaultPrice = el.type === 'default' ? `(${locale.labels.default})` : '';
        const prices = noPackageFormat
          ? el.price_per_unit.toFixed(2)
          : `${el.price_per_package}/${el.price_per_unit.toFixed(2)}`;
        return `${prices} ${currencies.uah} ${isDefaultPrice}: ${getResidual(el.residual)}`;
      }), []);

  const formatLabel = noPackageFormat
    ? `(${unitOfMeasurement || locale.labels.psc})`
    : `(${packagingFormat}/${unitOfMeasurement})`;

  const renderTooltip = useCallback(
    (): JSX.Element => (
      <Tooltip overlayClassName={'stock-list-price-item-tooltip'} trigger={'hover'} title={
            <List
              id={'stock-prices'}
              header={
                <div className='reassign-header'>
                  <span>{`${locale.labels.priceFor} ${formatLabel}`}</span>
                </div>
              }
              style={{ marginBottom: 20, fontSize: 14 }}
              bordered
              dataSource={getStockPrices}
              renderItem={(item) => (
                <List.Item style={{ fontSize: 14 }}>{item}</List.Item>
              )}
            />
      }>
          <Button size='small' type='primary' style={{ width: 30, borderRadius: 15, marginRight: 10 }}>
            <span className='icofont-listine-dots'></span>
          </Button>
      </Tooltip>
    ),
    [item]
  );
  
  const getUnitRow = useMemo(() => {
    return <>{(item?.unit_of_measurement !== 'pcs' || item?.packaging_format !== 'none') ? unitOfMeasurement + ': ' : ''}{item?.price_per_unit} {currencies.uah}</>;
  }, []);

  return <Row className='time-cell-row-container' align={'middle'} justify={'space-between'} style={{ gap: 15, flexWrap: 'nowrap' }}>
    <Col>
      {item?.packaging_format !== 'none' && <Row className='time-cell-row' style={{ width: 'max-content' }}>
        {`${packagingFormat}: ${item?.price_per_package} ${currencies.uah}`}
      </Row>}
      <Row className='time-cell-row' style={{ width: 'max-content' }}>
        {getUnitRow}
      </Row>
    </Col>
    {item?.prices?.length > 1 && <Col>{renderTooltip()}</Col>}
</Row>;
};

 