import { Form, FormInstance, Select } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInitialDataForAppointment } from 'redux/appointments/actions';
import { showModal } from 'redux/events/actions';

import AddAppointmentForm from '../addAppointment/AddAppointmentForm';
import EventForm from './EventForm';

const { Option } = Select;

enum FormType {
  event = 'event',
  appointment = 'appointment'
}
const AddEvent = (): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const locale = useLocale('private.appointments.event');
  const [addEventForm] = Form.useForm();
  const [addAppointmentForm] = Form.useForm();

  const initialDataForAppointment = useSelector(
    ({ appointments }: IAppState) => appointments.initialDataForAppointment
  );

  const [formType, setFormType] = useState<FormType>();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  useEffect(() => {
    if (initialDataForAppointment?.allDay) {
      setFormType(FormType.event);
    } else {
      setFormType(FormType.appointment);
    }

    return () => {
      setFormType(null);
    };
  }, [initialDataForAppointment?.allDay]);

  const selectForm = useCallback((): FormInstance => {
    if (formType === FormType.appointment) return addAppointmentForm;

    return addEventForm;
  }, [formType]);

  function modalTitle(): string {
    return formType === FormType.appointment
      ? locale.labels.createNewAppointment
      : locale.labels.createNewEvent;
  }

  const onConfirmCancelClick = () => {
    addAppointmentForm.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      {formType && (
        <VLXModal
          title={modalTitle()}
          form={selectForm()}
          open
          onModalClose={() => {
            dispatch(showModal(false));
            dispatch(setInitialDataForAppointment(null));
          }}
          width={800}
        >
          <Form.Item label={locale.labels.eventType}>
            <Select
              onChange={setFormType}
              className='event-type-select'
              defaultValue={formType}
              id='event-type'
            >
              <Option value={FormType.event}>{locale.labels.meeting}</Option>
              <Option value={FormType.appointment}>{locale.labels.appointment}</Option>
            </Select>
          </Form.Item>

          {formType === FormType.appointment ? (
            <AddAppointmentForm
              form={addAppointmentForm}
              initialValues={initialDataForAppointment}
              onClose={() => dispatch(showModal(false))}
              locale={locale}
            >
              <ViewDetailsButtonsGroup
                fixed
                accessGroup={userAccess.accesses.appointments}
                editMode={true}
                hideDeleteBtn={true}
                onCancelClick={() => {
                  if (addAppointmentForm.isFieldsTouched()) {
                    setShowCancelModal(true);
                  } else {
                    dispatch(showModal(false));
                  }
                }}
                onDeleteClick={() => {
                  setShowCancelModal(false);
                }}
              />
            </AddAppointmentForm>
          ) : (
            <EventForm
              form={addEventForm}
              onClose={() => dispatch(showModal(false))}
              initialValues={initialDataForAppointment}
              eventType={formType}
              locale={locale}
            >
              <ViewDetailsButtonsGroup
                fixed
                accessGroup={userAccess.accesses.events}
                editMode={true}
                hideDeleteBtn={true}
                onCancelClick={() => {
                  if (addEventForm.isFieldsTouched()) {
                    setShowCancelModal(true);
                  } else {
                    dispatch(showModal(false));
                  }
                }}
                onDeleteClick={() => {
                  setShowCancelModal(false);
                }}
              />
            </EventForm>
          )}
          <ConfirmCancelModal
            open={showCancelModal}
            onOk={onConfirmCancelClick}
            onCancel={() => setShowCancelModal(false)}
          />
        </VLXModal>
      )}
    </>
  );
};

export default AddEvent;
