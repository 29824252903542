import './EventsCalendar.scss';

import { getInitials } from './utils/name-utils';

export const ClinicCustomViewHeader = ({ children, clinicName, resource }): JSX.Element => (
  <div className='custom-event-header-wrapper'>
    <div className='avatar-container'>
      {resource.avatar ? (
        <img src={resource.clinicAvatar} alt={clinicName} className='avatar' />
      ) : (
        <div className='avatar-placeholder' style={{ backgroundColor: resource.color }}>
          {getInitials(resource.clinicName)}
        </div>
      )}
    </div>
    <div className='info-container'>
      <div className='label'>{resource.clinicName}</div>
    </div>
    {children}
  </div>
);
