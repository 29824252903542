import './scss/PrintPricesForm.scss';

import { Button, Col, Form, FormInstance, Select } from 'antd';
import { printPricesConfigurations } from 'constants/dictionary/default/selectOptions';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import { useGetResidual } from 'pages/inventory/stock/utils/useGetResidual';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { ISelectedStockPrices } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem';
import { requiredRule } from 'utils/form-rules';

import { PricesComponent } from './PricesComponent';
import { PricesListItem } from './PricesListItem';
import { IPriceComponent, PriceListType } from './types';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  locale: ILocale;
  stock: IStockItem;
  prices: Array<ISelectedStockPrices>;
}>;

type PrintPricesFormType = React.PropsWithChildren<{
  prices: IPriceComponent[];
  printConfig: Array<string>;
}>;

export const PrintPricesForm = ({
  children,
  form,
  locale,
  prices,
  stock
}: Props): JSX.Element => {
  const localizedPrintPricesConfigurations = useLocalizedList(printPricesConfigurations);
  const contentToPrint = useRef(null);

  const { unitOfMeasurement, packagingFormat, noPackageFormat } =
    useGetResidual(stock);

  const [pricesToPrint, setPricesToPrint] = useState(null);

  useEffect((): () => void => {
    return () => setPricesToPrint(null);
  }, []);

  useEffect(() => {
    if (pricesToPrint) handlePrint(null, () => contentToPrint.current);
  }, [pricesToPrint]);

  const getContentToPrint = useCallback((): ReactNode => {
    return (
      <div className='prices-print-wrapper'>
        <div className='prices-print-content' ref={contentToPrint}>
          {pricesToPrint}
        </div>
      </div>
    );
  }, [pricesToPrint]);

  const handlePrint = useReactToPrint({
    documentTitle: '',
    removeAfterPrint: true
  });

  const getPrintComponent = (item: IPriceComponent, printConfig: Array<string>) => {
    const price = prices.find(el => el.id === item.priceId);
    return <PricesComponent item={item} printConfig={printConfig} locale={locale} price={price} stock={stock} />;
  };

  const onSubmit = (data: PrintPricesFormType) => {
    const res = [];
    data.prices.forEach(item => {
      for (let i = 0; i < item.printQuantity; i++) {
        res.push(getPrintComponent(item, data?.printConfig));
      }
    });
    setPricesToPrint(res);
  };

  const PricesList = useCallback(
    ({ id, remove, price }: PriceListType) => (<PricesListItem id={id} remove={remove} locale={locale}
      prices={prices} stock={stock} price={price} />),
    [prices]
  );

  return (
    <Form
      onFinish={onSubmit}
      className='print-prices-form'
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={{
        prices: [{ quantity: 1, print: noPackageFormat ? 'piece' : 'both', price: prices?.find(el => el.type === 'default'), 
        priceId: prices?.find(el => el.type === 'default')?.id}], printConfig: ["name", "barcode", "price", "sku"]
      }}
    >
      <Col lg={12} xl={10} md={15}>
        <Form.Item label={locale.labels.content} name='printConfig' rules={[requiredRule(locale.errors.mandatoryField)]}>
          <Select
            filterOption={false}
            mode='multiple'
            options={localizedPrintPricesConfigurations}
          />
        </Form.Item>
      </Col>

      <span style={{ fontWeight: 600, fontSize: 14, marginTop: 25, display: 'block' }}>{stock?.name}</span>
      <div style={{ marginBottom: 25 }}>{!noPackageFormat && (<span>{locale.labels.format}: {packagingFormat}
        (1 {packagingFormat} = {stock?.quantity_per_package} {unitOfMeasurement})</span>)}
      </div>

      <Form.List name='prices'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <PricesList key={key} id={name} remove={remove} price={form.getFieldValue(['prices', key, 'price'])} />
            ))}
            <Form.Item>
              <Button type='primary' onClick={() => add()} style={{ borderRadius: 30 }}>
                <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
                <span>{locale.buttons.add}</span>
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {children}
      <div className='prices-print-content-wrapper'>
        {getContentToPrint()}
      </div>
    </Form>
  );
};
