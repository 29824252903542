import { CommonActions, SET_BE_ERROR, SET_LOADING, SHIFT_STATE } from './types';

export interface CommonState {
  loading: boolean
  backendError: boolean,
  shiftOpened: boolean
}

const initialState: CommonState = {
  loading: false,
  backendError: false,
  shiftOpened: false
};

export function commonReducer (
  state: CommonState = initialState,
  action: CommonActions
): any {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_BE_ERROR: {
      return {
        ...state,
        backendError: action.payload
      };
    }

    case SHIFT_STATE: {
      return {
        ...state,
        shiftOpened: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
