import { PlusOutlined } from '@ant-design/icons';
import { Checkbox, Form, FormInstance, notification, Upload } from 'antd';
import { TextEditor } from 'components/TextEditor';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useState } from 'react';
import {
  createPrintConfiguration,
  getPrintConfiguration,
  updatePrintConfiguration,
} from 'services/clinic/print/printService';

export type IPrintCongigurationParams = {
  print_anamnesis: boolean;
  print_free_items: boolean;
  header: string;
  footer: string;
  logo: string | any;
  id?: string;
};

type Props = React.PropsWithChildren<{
  form: FormInstance;
  setEditMode: (value: boolean) => void;
  onFieldsChange: (e: any) => void;
}>;

export const PrintConfigurationForm = ({
  form,
  children,
  setEditMode,
  onFieldsChange
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const locale = useLocale('public.modals.printConfiguration');

  const [currentConfig, setCurrentConfig] = useState(null);
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    getPrintConfiguration(clinicId).then(res => {
      if (!Array.isArray(res)) {
        const params = JSON.parse(res.additional_parameters);
        setCurrentConfig({logo: res.logo, ...params, id: res.id});
      }
    });
  }, []);

  useEffect(() => {
    form.resetFields();
    if (currentConfig?.logo) {
      setAvatar(currentConfig.logo);
    }
  }, [currentConfig]);

  const onSubmit = (data: IPrintCongigurationParams) => {
    const requestData = new FormData();

    if (data?.logo?.file?.originFileObj) {
      requestData.append('logo', data.logo.file.originFileObj);
    }
    requestData.append('additional_parameters', JSON.stringify({ footer: data.footer, header: data.header,
      print_anamnesis: data.print_anamnesis, print_free_items: data.print_free_items }));

    if (currentConfig?.id) {
      updatePrintConfiguration(clinicId, requestData, currentConfig.id).then(() => notification.success({ message: locale.buttons.configurationSaved }) ).then( () => setEditMode(false));
    } else {
      createPrintConfiguration(clinicId, requestData).then(() => notification.success({ message: locale.buttons.configurationSaved }) ).then( () => setEditMode(false));
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const getBase64 = (img: any, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={currentConfig}
      onFieldsChange={onFieldsChange}
    >
      <Form.Item name='logo' label={locale.labels.logo} >
        <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action=""
        onChange={info => {
          getBase64(info.file.originFileObj, (url) => setAvatar(url));

        }}
        >
          {avatar ? <img src={avatar} alt="avatar" style={{ overflow: 'hidden', maxHeight: 150 }} /> : uploadButton}
        </Upload>
      </Form.Item>
      <TextEditor label={locale.labels.header} name={['header']} />
      <TextEditor label={locale.labels.footer} name={['footer']} />
      <Form.Item name='print_anamnesis' label={locale.labels.printAnamnesis} valuePropName='checked'>
        <Checkbox/>
      </Form.Item>
      <Form.Item name='print_free_items' label={locale.labels.printItemsWith0Price} valuePropName='checked'>
        <Checkbox/>
      </Form.Item>
      {children}
    </Form>
  );
};
