
import { Checkbox, Col, Row } from 'antd';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import React from 'react';

import { IPrintCongigurationParams } from '../PrintConfigurationForm';

type Props = React.PropsWithChildren<{
  setShowBeforePrintModal: (state: boolean) => void;
  onPrintClick: () => void;
  isSaleBill: boolean;
  localPrintConfig: IPrintCongigurationParams;
  setLocalPrintConfig: (data: IPrintCongigurationParams) => void;
}>;

export const BeforePrintConfigurationModal = ({ setShowBeforePrintModal, onPrintClick, isSaleBill, localPrintConfig, setLocalPrintConfig, children }: Props): JSX.Element => {
  const userAccess = useUserAccess();
  const locale = useLocale('public.modals.printConfiguration');

  return (
    <>
      <VLXModal
        centered={true}
        open={true}
        onModalClose={() => setShowBeforePrintModal(false)}
        width={'80%'}
      >
        <div>
          <label style={{ fontSize: 18, fontWeight: 800, marginBottom: 20 }}>
            {isSaleBill ? locale.labels.printSaleBillDetails : locale.labels.printAppointmentBillDetails}
          </label>
          <Row>
            <Col span={24} md={12} lg={9} xl={7}>
              <div style={{ marginTop: '40px' }}>
                <label style={{ paddingRight: 14, fontSize: 16}}>
                  {locale.labels.printAnamnesis}
                </label>
                <Checkbox checked={localPrintConfig?.print_anamnesis} onChange={(e)=> setLocalPrintConfig({ ...localPrintConfig, print_anamnesis: e.target.checked })}/>
              </div>
              <div style={{ marginTop: 10 }}>
              <label style={{ paddingRight: 14, fontSize: 16 }}>
                {locale.labels.printItemsWith0Price}
              </label>
              <Checkbox checked={localPrintConfig?.print_free_items} onChange={(e)=> setLocalPrintConfig({ ...localPrintConfig, print_free_items: e.target.checked })}/>
              </div>
            </Col>
            <Col span={24} md={12} lg={15} xl={17}>
              <label style={{ fontWeight: 800, fontSize: 16 }}>{locale.labels.documentPreview}:</label>
              {children}
            </Col>
          </Row>
          <ViewDetailsButtonsGroup
            fixed
            submitButtonIcon={'icofont-print'}
            accessGroup={userAccess.accesses.invitations}
            onSaveModalClick={onPrintClick}
            editMode={true}
            hideDeleteBtn
            onCancelClick={() => setShowBeforePrintModal(false) }
          />
        </div>
      </VLXModal>
    </>
  );
};
