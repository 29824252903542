import { Button } from 'antd';
import useClinicId from 'hooks/useClinicId';
import {
  BeforePrintConfigurationModal,
} from 'layout/modals/printConfiguration/beforePrintConfig/BeforePrintConfigurationModal';
import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';
import { getPrintConfiguration } from 'services/clinic/print/printService';

import { BillContentToPrint } from './BillContentToPrint';

interface Props {
  data: IBillDetails;
  billId: string;
  buttonSize?: number;
  iconSize?: number;
  isDataLoading?: boolean;
}

export const BillContentToPrintWrapper = ({ data, billId, iconSize, buttonSize, isDataLoading }: Props): JSX.Element => {
  const clinicId = useClinicId();

  const [showBeforePrintModal, setShowBeforePrintModal] = useState(false);
  const [localPrintConfig, setLocalPrintConfig] = useState(null);

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: '',
    onBeforePrint: () => console.log('before printing...'),
    onAfterPrint: () => console.log('after printing...'),
    removeAfterPrint: true
  });

  const getContentToPrint = (): JSX.Element => (
    <div className='bill-print-wrapper'>
      <div className='bill-print-content' ref={contentToPrint}>
        <BillContentToPrint data={data} billId={+billId} currentPrintConfig={localPrintConfig} />
      </div>
    </div>
  );

  const initiatePrint = () => {
    getPrintConfiguration(+clinicId).then(res => {
      if (!Array.isArray(res)) {
        const params = JSON.parse(res.additional_parameters);
        setLocalPrintConfig({logo: res.logo, ...params, id: res.id});
      }
    });
    if (data?.appointment) {
      setShowBeforePrintModal(true);
    } else {
      handlePrint(null, () => contentToPrint.current);
    }
  };

  return (
    <>
      {getContentToPrint()}
      <Button
        onClick={initiatePrint}
        block
        disabled={!data || isDataLoading}
        type='primary'
        shape={'circle'}
        style={{ width: buttonSize ?? 60, height: buttonSize ?? 60, fontSize: iconSize ?? 24 }}
      >
        <span className='icofont icofont-print' />
      </Button>
      {showBeforePrintModal && <BeforePrintConfigurationModal setShowBeforePrintModal={setShowBeforePrintModal}
        onPrintClick={() => handlePrint(null, () => contentToPrint.current)} 
        isSaleBill={!!data?.sale} localPrintConfig={localPrintConfig} setLocalPrintConfig={setLocalPrintConfig} >
          <div className='bill-print-content preview-document-container'>
            <BillContentToPrint data={data} billId={+billId} currentPrintConfig={localPrintConfig} />
          </div>
        </BeforePrintConfigurationModal>}
    </>
  );
};
