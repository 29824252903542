import { createConsumer } from "@rails/actioncable";
import * as config from 'config/config-manager';
import {Consumer} from "@rails/actioncable";

let socketConnectionObj: Consumer = null;
export const getSocketConnection = (): Consumer => {
  if (socketConnectionObj) {
    return socketConnectionObj;
  }
  socketConnectionObj = createConsumer(config.socketUrl);
  return socketConnectionObj;
};

export const disconnectSocketConnection = () => {
  if (socketConnectionObj) {
    socketConnectionObj.disconnect();
    socketConnectionObj = null;
    console.log("WebSocket connection closed");
  }
};
