import { AxiosResponse } from 'axios';
import { IDocumentDetails } from 'interfaces/document-details';
import { IInvoiceDataItem } from 'services/interfaces/clinics/inventory/invoice/IInvoiceData';
import { ISortObject } from 'services/interfaces/ISortObject';

import baseClient from '../../../baseClient';
import { generateSearchQuery, sorting } from '../../../helpers/commonUtils';
import { CreateUpdateInvoiceRequest } from './models/createUpdateInvoiceRequest';
import { IndexInvoicesResponse } from './models/indexInvoicesResponse';

function url(clinic_id): string {
  return `/v1/clinics/${clinic_id}/invoices`;
}

export const createClinicInvoice = async (clinicId: number, data: CreateUpdateInvoiceRequest) =>
  baseClient.post(url(clinicId), data).then((response: AxiosResponse<IInvoiceDataItem>) => {
    return response.data;
  });

export const indexClinicInvoices = async (
  clinicId: number,
  page: number = 1,
  searchArray?: any[],
  itemsQuantity?: number,
  sortObject?: ISortObject
) =>
  baseClient
    .get(`${url(clinicId)}?page=${page}${itemsQuantity ? `&items=${itemsQuantity}`: ''}${generateSearchQuery(searchArray)}${sorting(sortObject)}`)
    .then((response: AxiosResponse<IndexInvoicesResponse>) => {
      return response.data;
    });

export const updateClinicInvoice = async (
  clinicId: number,
  invoice_id: number,
  data: CreateUpdateInvoiceRequest
) =>
  baseClient
    .put(`${url(clinicId)}/${invoice_id}`, data)
    .then((response: AxiosResponse<IInvoiceDataItem>) => {
      return response.data;
    });

export const showClinicInvoice = async (clinicId: number, invoice_id: number) =>
  baseClient
    .get(`${url(clinicId)}/${invoice_id}`)
    .then((response: AxiosResponse<IInvoiceDataItem>) => {
      return response.data;
    });

export const indexClinicInvoiceStockItems = async (clinicId: number, invoiceId: number, page: number = 1) =>
  baseClient
    .get(`${url(clinicId)}/${invoiceId}/items?page=${page}&sort_key=created_at&sort_order=desc`)
    .then((response: AxiosResponse<any>) => {
      return response.data;
    });

export const deleteClinicInvoice = async (clinicId: number, invoice_id: number) =>
  baseClient.delete(`${url(clinicId)}/${invoice_id}`).then((response: any) => {
    return response.data;
  });

  export const createClinicDocument = async (clinicId: number, invoice_id: number, data: IDocumentDetails) =>
  baseClient
    .post(`${url(clinicId)}/${invoice_id}/documents`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((response: any) => {
      return response.data;
    });

  export const getClinicDocumentsList = async (clinicId: number, invoice_id: number) =>
    baseClient
      .get(`${url(clinicId)}/${invoice_id}/documents`).then((response: any) => {
        return response.data;
      });


  export const removeClinicDocument = async (clinicId: number, invoice_id: number, documentId: number) =>
    baseClient
      .delete(`${url(clinicId)}/${invoice_id}/documents/${documentId}`)
      .then((response: any) => {
        return response.data;
      });
