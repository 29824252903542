import './BaseLayout.scss';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Layout, Row, Select, Tag } from 'antd';
import { useGetNetworkStatus } from 'hooks/useGetNetworkStatus';
import * as localStorage from 'local-storage';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import clinicDefaultLogo from 'assets/img/clinicDefaultLogo.png';
import LogoMobile from 'assets/img/logo-mobile.svg';
import LogoSvg from 'assets/img/logo.svg';
import useClinicId from 'hooks/useClinicId';
import { IAppState } from 'interfaces/app-state';
import { toggleSidebar } from 'redux/settings/actions';
import { Actions } from '../components/actions/Actions';
import FooterItems from '../components/footer/Footer';
import Logo from '../components/logo/Logo';
import VerticalLayout from '../vertical/Vertical';
import { getOpenedShift } from 'redux/shifts/actions';
import { consumeShiftsChannel } from 'lib/ShiftsConsumer';
import { useLocale } from 'hooks/useLocale';

const { Header, Content, Footer } = Layout;
const { Option } = Select;

const DAY_IN_MS = 1000 * 60 * 60 * 24;

const BaseLayout = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const clinicId = useClinicId();

  useGetNetworkStatus();

  const [shouldRefresh, setShouldRefresh] = useState(false);
  // const [showSettings, setShowSettings] = useState(false);
  const [openedMenuItem, setOpenedMenuItem] = useState<number>();

  const settings = useSelector(({ settings }: IAppState) => settings);
  const pageData = useSelector(({ pageData }: IAppState) => pageData);
  const shiftOpened = useSelector(({ common }: IAppState) => common.shiftOpened);
  const user = useSelector(({ user }: IAppState) => user);
  const authorized = user.id;
  const clinics = user.userAccesses;
  const locale = useLocale('private.header');

  useEffect(() => {
    document.documentElement.lang = user.locale === 'ukUA' ? 'uk' : 'en';
  }, [user.locale]);

  useEffect(() => {
    if (shouldRefresh) return;

    const id = setTimeout(() => setShouldRefresh(true), DAY_IN_MS);

    return () => {
      clearTimeout(id);
    };
  }, [shouldRefresh]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (shouldRefresh) {
      window.location.reload();
      setShouldRefresh(false);
    }
  }, [location]);

  // const toggleSettings = () => setShowSettings(!showSettings);

  const onClinicSelect = (clinicId: number): void => {
    dispatch({ type: 'RESET_STORE' });
    navigate(`clinic/${clinicId}/administration/info`);
    localStorage.set('storedSelectedClinicId', clinicId);
  };

  const clinicsList = useMemo(
    (): JSX.Element[] =>
      clinics.map((item) => (
        <Option
          className='clinic-select-option clinic-select-option__in-header'
          key={`${item.clinicName}${item.clinicId}`}
          title={item.clinicName}
          value={item.clinicId}
        >
          <div className='clinic-select-option__logo-container'>
            <img src={item?.clinicAvatar ? item?.clinicAvatar : clinicDefaultLogo} />
          </div>
          <div className='clinic-select-option__name-container'> {item.clinicName}</div>
        </Option>
      )),
    [clinics]
  );

  useEffect(() => {
    if (clinicId) {
      if (clinics?.some((el) => el.clinicId === clinicId)) {
        setOpenedMenuItem(clinicId);
        const storedSelectedClinicId = localStorage.get('storedSelectedClinicId');
        if (storedSelectedClinicId !== clinicId) {
          localStorage.set('storedSelectedClinicId', clinics[0].clinicId);
        }
      }
    } else {
      const storedSelectedClinicId = localStorage.get('storedSelectedClinicId');
      if (storedSelectedClinicId) {
        setOpenedMenuItem(Number(storedSelectedClinicId));
      } else if (clinics.length > 0) {
        setOpenedMenuItem(clinics[0].clinicId);
        localStorage.set('storedSelectedClinicId', clinics[0].clinicId);
      }
    }
  }, [clinicId, location, clinics]);

  // During the login, when no clinic is selected yet, get the shift status
  // for first clinic and open websocket connection
  useEffect(() => {
    if (clinics?.length > 0 && !clinicId) {
      dispatch(getOpenedShift(clinics[0].clinicId));
      consumeShiftsChannel(clinics[0].clinicId, localStorage.get('auth_token'), dispatch);
    }
  }, [clinics]);

  return (
    <Layout style={{ minHeight: '100%' }}>
      <Header>
        <Row
          align='middle'
          className={
            authorized ? 'header-container header-container__authorized' : 'header-container'
          }
        >
          <Col span={'auto'} sm={authorized ? 3 : 4} lg={0}>
            <Button
              type='link'
              size='large'
              style={{ color: 'white' }}
              icon={settings.sidebarOpened ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
              onClick={() => dispatch(toggleSidebar())}
            />
          </Col>
          <Row align={'middle'} className='header-container__left-side'>
            <Col className={'header-left-col'}>
              <Logo src={LogoSvg} className='logo-desktop' />
              <Logo src={LogoMobile} className='logo-mobile' />
              {/* {authorized ? <></> : <HorizontalLayout />} */}
            </Col>
            {!!authorized && clinics?.length > 0 && (
              <>
                <div className='header-pipe'>|</div>
                <Select className='clinic-select' onSelect={onClinicSelect} value={openedMenuItem}>
                  {clinicsList}
                </Select>
              </>
            )}

            {/*Shift status tag*/}
            {!!authorized && clinics?.length > 0 && (
              <Col className={'hidden-on-small'}>
                <Tag color={shiftOpened ? 'green' : 'red'}>
                  {shiftOpened ? locale.labels.shiftOpened : locale.labels.shiftClosed}
                </Tag>
              </Col>
            )}
          </Row>
          <Actions />
        </Row>
      </Header>
      <Layout style={{ alignItems: 'stretch' }}>
        <VerticalLayout />
        <Content className={!authorized ? 'vlx-content-area-unauthorized' : 'vlx-content-area'}>
          {pageData?.title && (
            <Breadcrumb
              separator='>'
              style={{ marginBottom: '10px', marginTop: '5px' }}
              items={[
                ...pageData.breadcrumbs.map((item) => ({
                  title: <Link to={item.route || '/dashboard'}>{item.title}</Link>
                })),
                { title: pageData.title }
              ]}
            ></Breadcrumb>
          )}
          <Outlet />
        </Content>
      </Layout>
      <Footer>
        <FooterItems />
      </Footer>
    </Layout>
  );
};

export default BaseLayout;
