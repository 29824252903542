import { AxiosResponse } from 'axios';
import { IPatientVaccinationsData } from 'interfaces/patient';
import { ISearchCriteria } from 'interfaces/search-criteria';

import baseClient from '../../baseClient';
import { generateSearchQuery, sorting } from '../../helpers/commonUtils';
import { ISortObject } from '../../interfaces/ISortObject';
import { CreatePatientRequest } from '../../interfaces/patients/create-patient-request';

const clinicsUrl: string = '/v1/clinics';

export const getSpeciesList = async () =>
  baseClient
    .get('/v1/species/')
    .then((response: any) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });

export const getClinicPatients = async (
  id: number,
  page?: number,
  searchValue?: ISearchCriteria[],
  sortObject?: ISortObject
) =>
  baseClient
    .get(
      `${clinicsUrl}/${id}/patients?page=${page}${generateSearchQuery(searchValue)}${sorting(
        sortObject
      )}`
    )
    .then((response: any) => {
      return response.data;
    });

export const getClinicVaccinations = async (
  id: number,
  page?: number,
  searchValue?: ISearchCriteria[],
  sortObject?: ISortObject
) =>
  baseClient
    .get(
      `${clinicsUrl}/${id}/vaccinations?page=${page}${generateSearchQuery(searchValue)}${sorting(
        sortObject
      )}`
    )
    .then((response: AxiosResponse<IPatientVaccinationsData>) => {
      return response.data;
    });

export const getClinicPatientDetails = async (id: number, patientId: number) =>
  baseClient.get(`${clinicsUrl}/${id}/patients/${patientId}`).then((response: any) => {
    return response.data;
  });

export const generateClinicVaccinationReport = async (id: number, searchArray: ISearchCriteria[]) =>
  baseClient.get(`${clinicsUrl}/${id}/vaccination_report?${generateSearchQuery(searchArray)}`,
    { responseType: 'blob' }).then((response: any) => {
      return response;
    }
  );

export const createClinicPatient = async (clinicId: number, data: CreatePatientRequest) =>
  baseClient.post(`/v1/clinics/${clinicId}/patients`, data).then((response: any) => {
    return response.data;
  });

export const updateClinicPatient = async (
  clinicId: number,
  data: CreatePatientRequest,
  patientId: number
) =>
  baseClient.put(`/v1/clinics/${clinicId}/patients/${patientId}`, data).then((response: any) => {
    return response.data;
  });

export const removeClinicPatient = async (clinicId: number, patientId: number) =>
  baseClient.delete(`/v1/clinics/${clinicId}/patients/${patientId}`).then((response: any) => {
    return response.data;
  });

export const uploadAvatar = async (clinicId, patientId, avatarData) =>
  baseClient
    .post(`/v1/clinics/${clinicId}/patients/${patientId}/avatar`, avatarData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((response: any) => {
      return response.data;
    });

export const getClinicPatientDocumentsList = async (id: number, patientId: number) =>
  baseClient.get(`${clinicsUrl}/${id}/patients/${patientId}/documents`).then((response: any) => {
    return response.data;
  });

export const getClinicPatientDocument = async (id: number, patientId: number, documentId: number) =>
  baseClient
    .get(`${clinicsUrl}/${id}/patients/${patientId}/documents/${documentId}`)
    .then((response: any) => {
      return response.data;
    });

export const createClinicPatientDocument = async (
  clinicId: number,
  patientId: number,
  data: CreatePatientRequest
) =>
  baseClient
    .post(`/v1/clinics/${clinicId}/patients/${patientId}/documents`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((response: any) => {
      return response.data;
    });
