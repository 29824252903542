import './scss/PrintPricesForm.scss';

import { Button, Col, Form, InputNumber, Row, Select } from 'antd';
import { useLocale } from 'hooks/useLocale';
import { ILocale } from 'interfaces/locale';
import { useGetResidual } from 'pages/inventory/stock/utils/useGetResidual';
import React, { useState } from 'react';
import { ISelectedStockPrices } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';
import { IStockItem, IStockPricing } from 'services/interfaces/clinics/inventory/stock/IStockItem';
import { requiredRule } from 'utils/form-rules';

const { Option } = Select;

type Props = React.PropsWithChildren<{
  id: number;
  remove?: (item) => void;
  locale: ILocale;
  stock: IStockItem;
  prices: ISelectedStockPrices[] | IStockPricing[];
  price?: ISelectedStockPrices;
}>;

export const PricesListItem = ({
  id,
  remove,
  locale,
  stock,
  prices,
  price
}: Props): JSX.Element => {
  const currencies = useLocale('private.currencies').labels;

  const { unitOfMeasurement, packagingFormat, noPackageFormat } =
    useGetResidual(stock);

  const [selectedPriceId, setSelectedPriceId] = useState(null);

  const packagingPrintOptions = <>
    <Option key='bothPrices' title={locale.labels.cash} value='both'>
      {locale.labels.bothPrices}
    </Option>
    <Option key='priceForPackage' title={locale.labels.cash} value='package'>
      {locale.labels.priceForPackage}
    </Option>
    <Option key='priceForPiece' title={locale.labels.cash} value='piece'>
      {locale.labels.priceForPiece}
    </Option>
  </>;

  const pricesOptions = prices?.map(
    (item): JSX.Element => (
      <Option key={item.id} value={item.id}>
        {!noPackageFormat && `${packagingFormat}: ${item.price_per_package} ${currencies.uah} / `}
        {unitOfMeasurement}: {item.price_per_unit} {currencies.uah}
        {item.type === 'default' ? locale.labels.default : ''}
      </Option>
    )
  );

  const quantityTooltip = (): string => {
    let priceItem = price;
    if (!price?.id) {
      priceItem = (prices as ISelectedStockPrices[])?.find(el => el.id === selectedPriceId);
    }
    if (!priceItem || !prices?.length) return '';
    const remainingPackages = !noPackageFormat ? `${priceItem?.quantity} ${packagingFormat}` : '';
    const remainingUnits = noPackageFormat ? `${priceItem?.residual} ${unitOfMeasurement}`
      : `${priceItem?.residual % stock?.quantity_per_package} ${unitOfMeasurement}`;
    return locale.labels.remainingTooltip.replace(/%1/, `${remainingPackages} ${remainingUnits}`);
  };

  return (
    <Row key={id} gutter={24} className='invitation-creation-form-row'>
      <Col lg={8} md={12} sm={12} xs={24}>
        <Form.Item label={locale.labels.price} name={[id, 'priceId']} rules={[requiredRule()]} extra={quantityTooltip()}>
          <Select onChange={setSelectedPriceId}>{pricesOptions}</Select>
        </Form.Item>
      </Col>
      {!noPackageFormat && <Col lg={6} md={12} sm={12} xs={24}>
        <Form.Item label={locale.labels.print} name={[id, 'print']} initialValue={noPackageFormat ? 'piece' : 'both'}>
          <Select disabled={noPackageFormat}>{packagingPrintOptions}</Select>
        </Form.Item>
      </Col>}
      <Col lg={6} md={12} sm={12} xs={id === 0 ? 24 : 20}>
        <Form.Item label={locale.labels.quantity} name={[id, 'printQuantity']} rules={[requiredRule()]} initialValue={1}>
          <InputNumber/>
        </Form.Item>
      </Col>

      {id !== 0 && <Col lg={4} md={8} sm={12} xs={4}>
        <Form.Item label={' '}>
          <Button
            onClick={() => {
              remove(id);
            }}
            danger
            type='primary'
            shape={'circle'}
            className='remove-btn'
          >
            <span className='icofont icofont-trash mr-2' />
          </Button>
        </Form.Item>
      </Col>}
    </Row>
  );
};
